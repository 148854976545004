import React, { useRef, useState,useEffect,useContext } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import defaultIcon from "./../../assets/images/icon/business-default.svg";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import API_SERVICE from "../../services/api-service.js";
import Parameters from "../../parameters.ts";
import { AppContext } from "../../context";
import MobileMenu from "../../components/mobileMenu";
import useFullPageLoader from "../../hooks/FullPageLoader";




function BusinessList() {
  const navigate = useNavigate();

  const { users } = useContext(AppContext);

  useEffect(() => {
    if (users) {
      GetScreen();
    } 
  }, [users]);

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  const imageRef = useRef();
  const buttonRef = useRef(null);

  const [busName, setBusName] = useState();
  const [busAddress, setBusAddress] = useState();
  const [busReg, setBusReg] = useState();
  const [data, setData] = useState();
  const [dataAddress, setDataAddress] = useState();
  const [dataReg, setDataReg] = useState();

  const handleChangeName = (event) => {
    let fls1 = event.target.files[0];
    if(fls1.type == "image/png" || fls1.type == "image/jpeg"){

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setData({ image: e.target.result });
        setBusName(fls1);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }else{
    toast.error("Only files with the following extensions are allowed: PNG,JPEG,JPG");


  }
  };

  const handleClickName = () => {
    const input = document.getElementById("file-input");
    if (input) {
      input.click();
    }
  };

  const handleChangeAddress = (event) => {
    let fls1 = event.target.files[0];
    if(fls1.type == "image/png" || fls1.type == "image/jpeg"){

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setDataAddress({ image: e.target.result });
        setBusAddress(fls1);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }else{
    toast.error("Only files with the following extensions are allowed: PNG,JPEG,JPG");

  }
  };

  const handleClickAddress = () => {
    const input = document.getElementById("file-input1");
    if (input) {
      input.click();
    }
  };

  const handleChangeReg = (event) => {
    let fls1 = event.target.files[0];
    // setBusReg(fls1);
    if(fls1.type == "image/png" || fls1.type == "image/jpeg"){

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setDataReg({ image: e.target.result });
    setBusReg(fls1);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }else{
    toast.error("Only files with the following extensions are allowed: PNG,JPEG,JPG");


  }
  };

  const handleClickReg = () => {
    const input = document.getElementById("file-input2");
    if (input) {
      input.click();
    }
  };

  const buildPayload = (formData: any) => {
    const payload = {
      business_name: busName,
      business_address: busAddress,
      business_registration: busReg,
    };
    return payload;
  };
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  
  const handleSubmit = () => {
    if (data?.image == null) {
      toast.error("Please upload registered business document.");
    } else if(dataAddress?.image ==  null) {
      toast.error("Please upload register business address document.");
    } else if (dataReg?.image == null) {
      toast.error("Please upload registered business certificate.");
    } else {
      const fd = new FormData();
      fd.append("business_name", busName);
      fd.append("business_address", busAddress);
      fd.append("business_registration", busReg);
      const user = localStorage.getItem("userAuth");
      buttonRef.current.disabled = true;
      showLoader();

      
      const result = axios
        .post(`${Parameters.ApiUrlOne}business-profi-verifi`, fd, {
          headers: { Authorization: `Bearer ${JSON.parse(user).token}` },
        })
        .then((response) => {
          buttonRef.current.disabled = false;
          hideLoader();
          if (response.data.status == true) {
            navigate("/request-sent");
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <>
    <MobileMenu />
      <main className="main-section">
        <section className="common-section business-section">
          {/* <Button className="back-btn d-block d-md-none" variant="link">
            <Link to="/anchor-locations">
              {" "}
              <img src={backBtn} alt="menu" />
            </Link>
          </Button> */}
          <Container>
            <div className="auth-block">
              <div className="head text-center">
                <Row className="justify-content-center">
                  <Col md={10} xl={6}>
                    <h3>Let’s get a few more details </h3>
                    <p className="mb-0">
                      Send us well-lit, clear, and legible photos of the
                      following document(s) to verify your business.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="business-list">
                <div className="item d-flex flex-column-reverse flex-md-row">
                  <div className="img-block me-4">
                    <img
                      src={data ? data.image : defaultIcon}
                      alt="icon"
                      onClick={handleClickName}
                      id="OpenImgUpload"
                    />
                    <input
                      id="file-input"
                      ref={imageRef}
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleChangeName}
                    />
                  </div>
                  <div>
                    <h5>Business Name and TIN/EIN</h5>
                    <p>
                      A bank statement, or IRS letter showing TIN/EIN,
                      government document with TIN/EIN, or 3rd party prepared
                      tax return.
                    </p>
                  </div>
                </div>
                <div className="item d-flex flex-column-reverse flex-md-row">
                  <div className="img-block me-4">
                    <img
                      src={dataAddress ? dataAddress.image : defaultIcon}
                      alt="icon"
                      onClick={handleClickAddress}
                      id="OpenImgUpload"
                    />
                    <input
                      id="file-input1"
                      ref={imageRef}
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleChangeAddress}
                    />
                  </div>
                  <div>
                    <h5>Business address</h5>
                    <p>
                      A credit card statement, a utilities bill, an insurance
                      document, a tax statement, or business license/ permit.
                    </p>
                  </div>
                </div>
                <div className="item d-flex flex-column-reverse flex-md-row">
                  <div className="img-block me-4">
                    <img
                      src={dataReg ? dataReg.image : defaultIcon}
                      alt="icon"
                      onClick={handleClickReg}
                      id="OpenImgUpload"
                    />
                    <input
                      id="file-input2"
                      ref={imageRef}
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleChangeReg}
                    />
                  </div>
                  <div>
                    <h5>Business registration</h5>
                    <p>Certificate of Good Standing.</p>
                  </div>
                </div>
                <Row md={10} xl={4} className="justify-content-center">
                  <Button
                    className="theme-btn"
                    style={{ width: "300px" }}
                    onClick={handleSubmit}
                    ref={buttonRef}
                  >
                    <span>Submit</span>
                  </Button>
                </Row>
              </div>
            </div>
          {loader}

          </Container>
        </section>
      </main>
    </>
  );
}
export default BusinessList;
