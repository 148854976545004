import AXIOS from "axios";
// import { useNavigate } from 'react-router-dom';
import Parameters from "../parameters.ts";
// import { AppContext } from "../context";
import { API_CONFIG } from "./config.ts";
function getPublicInstance(firstServer = true) {
  const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo;
  const headers = {};

  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl + "/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

function getProtectedInstance(firstServer = true) {
  const user = localStorage.getItem("userAuth");
  const headers: any = {};
  const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo;
  if (user) {
    headers["Bearer_Token"] = JSON.parse(user).token;
  }
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(user).token}`,
      ...headers,
    },
  });
}
const getToken = () => {
  const user = localStorage.getItem("userAuth");
  const token = {};
  return (token["token"] = JSON.parse(user).token);
};

const handleResponse = (result) => {
  if (result.response.status === 401) {
    const token = getToken();
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    LogOut(token);
    localStorage.removeItem("userAuth");
    localStorage.removeItem("screen");
    return Promise.reject(result);
  }
  return result;
};

function handleErrors(error) {
  //   let message = "Something went wrong!!";
  //   if (error && error.response && error?.response?.error) {
  //     let parsedError: any = "";
  //     message = error.response.data.error;
  //     if (typeof error.response.data.error === "string") {
  //       try {
  //         parsedError = JSON.parse(error.response.data.error);
  //         if (parsedError["errorMessage"]) {
  //           message = parsedError["errorMessage"];
  //         } else {
  //           const allErrors = Array();
  //           for (let i in parsedError) {
  //             allErrors.push(parsedError[i]);
  //           }
  //           message = allErrors.join(", ");
  //         }
  //       } catch (e) {}
  //     }
  //   } else if (error?.response?.data?.message) {
  //     message = error.response.data.message;
  //   } else if (
  //     error &&
  //     error.response &&
  //     error.response.data &&
  //     error.response?.data?.message
  //   ) {
  //     return (message = error.response.data.message);
  //   } else if (
  //     error &&
  //     error.response &&
  //     error.response.data &&
  //     error?.response?.data?.errors
  //   ) {
  //     let e = Array();
  //     for (let i in error.response?.data?.errors) {
  //       e.push(`${i}: ${error.response.data.errors[i]}`);
  //     }
  //     message = e.join(",");
  //   } else if (typeof error === "string") {
  //     message = error;
  //   }
  //   return message;
}
async function SignUp(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.SignUp, payload);
}
async function Login(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.Login, payload);
}
async function ForgotPassword(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.ForgotPassword, payload);
}

async function OTPVerify(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.OtpVerify, payload);
}
async function ChangePassword(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.ChangePassword, payload);
}

async function GetCategory(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.GetCategory, payload);
}
async function GetCareer(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.GetCareer, payload);
}

async function AddLocation(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.AddLocation, payload);
}

async function UserLocationDetails(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.UserLocationDetails, payload);
}

async function LocationStatusChange(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.LocationStatusChange, payload);
}

async function LocationDelete(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.LocationDelete, payload);
}

async function GetlLocationDetail(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.GetlLocationDetail, payload);
}

async function GetScreen(payload) {
  const instance = getProtectedInstance();
  return await instance.get(API_CONFIG.GetScreen, payload);
  // .then((res) => res.data)
  // .catch(error => handleResponse(error))
}
// return await instance.get(API_CONFIG.GetScreen, payload);

async function EditLocation(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.EditLocation, payload);
  // .catch(error => handleResponse(error))
}

async function LogOut(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.LogOut, payload);
}

async function Faq(payload) {
  const instance = getPublicInstance();
  return await instance.get(API_CONFIG.Faq, payload);
}

async function VerifyEmail(payload) {
  const instance = getPublicInstance();
  return await instance.post(API_CONFIG.VerifyEmail, payload);
}

async function ChangePasswordHome(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.ChangePasswordHome, payload);
}

async function GetProfile(payload) {
  const instance = getProtectedInstance();
  return await instance.get(API_CONFIG.GetProfile, payload);
}

async function EditProfile(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.EditProfile, payload);
}

async function AddBankDetail(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.AddBankDetail, payload);
}

async function AddBusinessDetails(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.AddBusinessDetails, payload);
}

async function LocationOrders(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.LocationOrders, payload);
}

async function AllNotifications(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.Notifications, payload);
}

async function TransactionList(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.TransactionList, payload);
}

async function LocationsDropDown(payload) {
  const instance = getProtectedInstance();
  return await instance.get(API_CONFIG.LocationsDropDown, payload);
}

async function WithdrawanTransaction(payload) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.WithdrawanTransaction, payload);
}

async function CheckWIthdrawan(payload) {
  const instance = getProtectedInstance();
  return await instance.get(API_CONFIG.CheckWIthdrawan, payload);
}

async function UpdateChat(payload) {
  const instance = getProtectedInstance();
  return await instance.get(API_CONFIG.UpdateChat, payload);
}


const API_SERVICE = {
  UpdateChat,
  ChangePasswordHome,
  SignUp,
  handleErrors,
  Login,
  ForgotPassword,
  OTPVerify,
  ChangePassword,
  GetCategory,
  GetCareer,
  AddLocation,
  UserLocationDetails,
  LocationStatusChange,
  LocationDelete,
  GetlLocationDetail,
  GetScreen,
  EditLocation,
  LogOut,
  Faq,
  VerifyEmail,
  handleResponse,
  GetProfile,
  EditProfile,
  AddBankDetail,
  AddBusinessDetails,
  LocationOrders,
  AllNotifications,
  TransactionList,
  LocationsDropDown,
  WithdrawanTransaction,
  CheckWIthdrawan
};
export default API_SERVICE;
