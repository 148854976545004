export const API_CONFIG = {
    SignUp: 'dz-user-signup',
    Login : 'dz-user-login',
    ForgotPassword:'dz-user-forgot-password',
    OtpVerify:'dz-user-forgot-otp-verify',
    ChangePassword:'dz-user-reset-password',
    GetCategory:"dz-category-list",
    GetCareer:"dz-career-list",
    AddLocation:"dz-add-user-location",
    UserLocationDetails:"user-location-details",
    LocationStatusChange:"location-status-change",
    LocationDelete:"dz-delete-user-location",
    GetlLocationDetail:"get-location-detail",
    GetScreen:"status-detail",
    EditLocation:"dz-edit-user-location",
    Faq:"daizy-faq",
    LogOut:"daizy-user-logout",
    VerifyEmail:"usr-prof-appr-using-email",
    ChangePasswordHome:"daizy-change-password",
    GetProfile:"daizy-user-profile-detail",
    EditProfile:"daizy-edit-profile",
    AddBankDetail:"add-bank-detail",
    AddBusinessDetails:"business-profi-verifi",
    LocationOrders:"order-detail",
    Notifications:"notification-list",
    TransactionList:"wallet-transacation-list",
    LocationsDropDown:"list-user-locations",
    WithdrawanTransaction:"withdraw-request",
    CheckWIthdrawan:"check-withdraw-request-pending",
    UpdateChat:"update-chat-msg-status"
   
};
