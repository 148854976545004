import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SupportChat from "../../components/supportChat";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "../../services/api-service";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import parameters from "./../../parameters.ts";
import "./hello.scss";
// import moment from 'moment';
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  child,
  orderByChild,
  update,
  connectDatabaseEmulator,
} from "firebase/database";
import Select from "react-select";
import DateFormat from "./../../services/Utility.ts";
import useFullPageLoader from "../../hooks/FullPageLoader";

let timer;
function Transaction() {
  let { id } = useParams();

  const [data, setData] = useState();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const final = date.toLocaleString(navigator.language, {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    return final;
  };

  const listInnerRef = useRef();
  // const onScroll = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       // setCurrPage(currPage + 1);
  //       // fetchData(currPage + 1);
  //       setPage(currPage + 1);
  //       // console.log(currPage +1)
  //     }
  //   }
  // };
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    getAllCategory();
  }, []);

  const [locations, setLocations] = useState([]);
  const sample_arr = [];
  const getAllCategory = () => {
    showLoader();

    API_SERVICE.LocationsDropDown().then(({ data }) => {
      hideLoader();
      if (data?.data) {
        // setLocations(data.data);
        setLocations(
          data?.data.map((item) => {
            return { value: item.id, label: item.address };
          })
        );
      }
    });
  };

  // console.log(locations)

  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  var [userList, setUserList] = useState([]);
  const [userList1, setUserList1] = useState([]);

  // const [data1, setData1] = useState([]);

  const [lastList, setLastList] = useState(false);
  const [LE, setLE] = useState(false);

  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");

  const [page, setPage] = useState(1);

  useEffect(() => {
    setUserList([]);
  }, [query, location]);

  const observer = useRef();

  const lastElement = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  };

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const fd = {
        page: page,
        search: query,
        per_page: 10,
        location_id: location ? location.value : "",
      };
      setLoading(true);
      showLoader();

      await API_SERVICE.TransactionList(fd).then(({ data }) => {
        hideLoader();
        setData(data?.data);
        if (!data.data.data.length) {
          setLastList(true);
          return;
        }
        // setPrevPage(currPage);
        setLoading(false);
        setHasMore(data.data.data.length > 0);
        setUserList((prevBooks) => {
          return [...new Set([...prevBooks, ...data.data.data])];
        });
      });
    };
    fetchData();
  }, [query, page, location]);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [locationsd, setlocationsd] = useState("");

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  let [value, setValue] = useState();

  const handleChange = (event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setQuery(event.target.value);
      setPage(1);
    }, 1000);
    event.preventDefault();
  };

  const selectInputRef = useRef();

  const ids = userList.map((o) => o.id);
  const filtered = userList.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );

  const customStyles = {
    control: (base) => ({
      ...base,
      width: 330,
      fontSize: "16px",
      // fontWeight: 'bold',
      borderRadius: "25px",
      padding: "4px 4px 2px 2px",
      // border: '1px solid #21274F !important',
      boxShadow: "none",
      "&:focus": {
        border: "1px solid black !important",
      },
      "@media only screen and (max-width: 767px)": {
        ...base["@media only screen and (max-width: 767px)"],
        width: 325,
      },
      "@media only screen and (min-width: 768px) and (max-width: 991px)": {
        ...base[
          "@media only screen and (min-width: 768px) and (max-width: 991px)"
        ],
        width: 625,
      },
      "@media only screen and (max-width: 480px)": {
        ...base["@media only screen and (max-width: 480px)"],
        width: 340,
      },
    }),
  };

  let [size, setSize] = useState("");

  const handleChange1 = (like) => {
    setLocation(like);
    setPage(1);
    setSize(like);
  };

  return (
    <>
      <Button className="back-btn d-block d-md-none" variant="link">
        <Link to="/anchor-locations">
          <img src={backBtn} alt="menu" />
        </Link>
      </Button>
      <main className="main-section">
        <section className="common-section auth-section anchor-locations">
          <div className="d-block d-md-none text-center">
            <img src={textLogo} alt="arrow" />
          </div>
          <Container>
            <div className="auth-block" style={{ width: "884px" }}>
              <Row className="">
                <Col
                  sm={12}
                  className="left-block"
                  style={{ borderLeft: "none", width: "100%" }}
                >
                  <div className="head d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Transaction List</h4>
                  </div>

                  {/* <Form
                      className="d-flex justify-content-between "
                      id="forma"
                      style={{ padding: "10px" }}
                    >
                      <Form.Control
                        style={{ marginRight: "2%" }}
                        type="text"
                        placeholder="Search"
                        // onChange={(e) => setField("search", e.target.value)}
                        onChange={(e) => handleChange(e)}
                        isInvalid={!!errors.search}
                      />
                      <Select
                        options={locations}
                        isClearable={true}
                        styles={customStyles}
                        onChange={handleChange1}
                        placeholder="Select Location"
                       
                        value={size}
                      />
                    
                    </Form> */}
                  <form class="form-inline justify-content-between">
                    {/* <input type="email" id="email" placeholder="Enter email" name="email"/> */}
                    <Form.Control
                      style={{ marginRight: "1%" }}
                      type="text"
                      placeholder="Search"
                      // onChange={(e) => setField("search", e.target.value)}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.search}
                    />
                    <Select
                      options={locations}
                      isClearable={true}
                      styles={customStyles}
                      onChange={handleChange1}
                      placeholder="Select Location"
                      value={size}
                    />
                  </form>

                  <div className="report-list" style={{ paddingTop: "4px" }}>
                    {filtered.map((obj, index) => {
                      if (filtered.length === index + 1) {
                        return (
                          <div
                            className="item mb-2"
                            key={index}
                            ref={lastElement}
                          >
                            <div
                              className="head-bar d-flex justify-content-between"
                              style={{ fontSize: "13px" }}
                            >
                              <div className="d-flex justify-content-between">
                                <p className="ids">
                                  <b>#{obj?.transaction_id}</b>
                                </p>
                                <p
                                  style={{
                                    color:
                                      obj.transaction_type == "Credit"
                                        ? "#008000"
                                        : obj?.transaction_type == "Debit"
                                        ? "FF0000"
                                        : null,
                                    padding: "5px 5px 5px 5px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {" "}
                                  {obj.transaction_type}
                                </p>
                              </div>
                              <p className="date-time">
                                {/* {formatDate(obj?.transaction_date)} */}
                                {/* {moment(obj?.transaction_date).format("MM/DD/YYYY hh:mm A")} */}
                                {DateFormat(obj?.transaction_date)}
                              </p>
                              <p className="date-time">${obj?.amount}</p>
                            </div>
                            <div
                              className="head-bar d-flex justify-content-between"
                              style={{
                                fontSize: "13px",
                                borderBottom: "none",
                                paddingBottom: "1%",
                              }}
                            >
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <p className="ids">{obj?.description}</p>
                                <p
                                  style={{
                                    color:
                                      obj?.status == "Pending"
                                        ? "#DACA7C"
                                        : obj?.status == "Approve"
                                        ? "#008000"
                                        : obj?.status == "Reject"
                                        ? "FF0000"
                                        : null,
                                    padding: "5px 5px 5px 5px",
                                    borderRadius: "10px",
                                    width: "max-content",
                                  }}
                                >
                                  {obj?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="item mb-2" key={index}>
                            <div
                              className="head-bar d-flex justify-content-between"
                              style={{ fontSize: "13px" }}
                            >
                              <div className="d-flex justify-content-between">
                                <p className="ids">
                                  <b>#{obj?.transaction_id}</b>
                                </p>
                                <p
                                  style={{
                                    color:
                                      obj.transaction_type == "Credit"
                                        ? "#008000"
                                        : obj?.transaction_type == "Debit"
                                        ? "#FF0000"
                                        : null,
                                    padding: "5px 5px 5px 5px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {" "}
                                  {obj.transaction_type}
                                </p>
                              </div>
                              <p className="date-time">
                                {/* {formatDate(obj?.transaction_date)} */}
                                {DateFormat(obj?.transaction_date)}
                              </p>
                              <p className="date-time">${obj?.amount}</p>
                            </div>

                            <div
                              className="head-bar d-flex justify-content-between"
                              style={{
                                fontSize: "13px",
                                borderBottom: "none",
                                paddingBottom: "1%",
                              }}
                            >
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <p className="ids">{obj?.description}</p>
                                <p
                                  style={{
                                    color:
                                      obj?.status == "Pending"
                                        ? "#E8A317"
                                        : obj?.status == "Approve"
                                        ? "#008000"
                                        : obj?.status == "Reject"
                                        ? "FF0000"
                                        : null,
                                    padding: "5px 5px 5px 5px",
                                    borderRadius: "10px",
                                    width: "max-content",
                                  }}
                                >
                                  {obj?.status == "Approve"
                                    ? "Approved"
                                    : obj?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {filtered.length == 0 && <p>No Record Found</p>}
                  </div>
                </Col>
              </Row>
            </div>
            {loader}
          </Container>
        </section>
      </main>
      <SupportChat />
    </>
  );
}
export default Transaction;
