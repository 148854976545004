// import { useMedia } from "react-use";
// import API_SERVICE from "./api-service";
import moment from 'moment';


// export const useLayoutMedia = (layoutsToVerify: string[]) => {
//   let layoutMode: "Mobile" | "TabletPortrait" | "TabletLandscape" | "Desktop" =
//     "Desktop";
//   if (useMedia("(max-width: 1024px)")) {
//     layoutMode = "TabletLandscape";
//   }
//   if (useMedia("(max-width: 768px)")) {
//     layoutMode = "TabletPortrait";
//   }
//   if (useMedia("(max-width: 576px)")) {
//     layoutMode = "Mobile";
//   }
//   return layoutsToVerify.indexOf(layoutMode) > -1;
// };

// export const INRFormatter = (str: any, showSymbol = true) => {
//   let s = str + "";
//   if (!str) {
//     return "₹ 0";
//   }
//   // TODO: Optimize this one
//   let lastThree = s.substring(s.length - 3);
//   const otherNumbers = s.substring(0, s.length - 3);
//   if (otherNumbers != "") lastThree = "," + lastThree;
//   s = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
//   if (showSymbol) {
//     s = "₹ " + s;
//   }
//   return s;
// };
// export const getBusinessType = () => {
//   return "ADMIN";
// };
// export const filteredList = (searchKey: string, items: any[]) => {
//   if (!searchKey) {
//     return items;
//   }
//   return items.filter((c: any) => {
//     let passed = false;
//     for (const key in c) {
//       const value = c[key];
//       if (
//         value &&
//         typeof value === "string" &&
//         value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
//       ) {
//         passed = true;
//       }
//     }
//     return passed;
//   });
// };

// function parseDotNotation(str: string, val: any, obj: any) {
//   let currentObj = obj,
//     keys = str.split("."),
//     i,
//     l = Math.max(1, keys.length - 1),
//     key;

//   for (i = 0; i < l; ++i) {
//     key = keys[i];
//     currentObj[key] = currentObj[key] || {};
//     currentObj = currentObj[key];
//   }

//   currentObj[keys[i]] = val;
//   delete obj[str];
// }

// export function dotToObject(obj: any) {
//   for (let key in obj) {
//     if (key.indexOf(".") !== -1) {
//       parseDotNotation(key, obj[key], obj);
//     }
//   }
//   return obj;
// }

// export const camelToNormal = (text: string) => {
//   if (!text) {
//     return text;
//   }

//   let result = text.replace(/([A-Z])/g, " $1");
//   let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
//   return finalResult;
// };

// export function objectToDot(obj: any) {
//   let res: any = {};
//   (function recurse(obj, current) {
//     for (let key in obj) {
//       let value = obj[key];
//       let newKey = current ? current + "." + key : key;
//       if (value && typeof value === "object") {
//         // @ts-ignore
//         recurse(value, newKey);
//       } else {
//         res[newKey] = value;
//       }
//     }
//   })(obj);
//   return res;
// }

export function DateFormat(obj: string) {
  const formate =  moment(obj).format("YYYY/MM/DD hh:mm A")
  // const formate =  moment(obj).format("D MMMM, YYYY hh:mm A")
  return formate;
}
export default DateFormat

// export const base64ToArrayBuffer = (base64) => {
//   var binaryString = window.atob(base64);
//   var binaryLen = binaryString.length;
//   var bytes = new Uint8Array(binaryLen);
//   for (var i = 0; i < binaryLen; i++) {
//     var ascii = binaryString.charCodeAt(i);
//     bytes[i] = ascii;
//   }
//   return bytes;
// };

// export const saveByteArray = (function () {
//   var a = document.createElement("a") as any;
//   document.body.appendChild(a);
//   a.style = "display: none";
//   return function (data, name) {
//     var blob = new Blob(data, { type: "octet/stream" }),
//       url = window.URL.createObjectURL(blob);
//     a.href = url;
//     a.download = name;
//     a.click();
//     window.URL.revokeObjectURL(url);
//   };
// })();

// export const hasUserRole = () => {
//   // const userPermissions = JSON.parse(
//   //   localStorage.getItem("userAuth") || "[]"
//   // );
//   const result = (e) => {
   
//       API_SERVICE.GetScreen()
//         .then(({ data }) => {
//         })
//         .catch((error) => {
//         });
//   };
//   return result;
// };
