import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import { AppContext } from "../../context";
import showPwdImg from "../../assets/images/icon/eye-icon.svg";
import hidePwdImg from "../../assets/images/icon/eye-close-icon.svg";
import useFullPageLoader from "../../hooks/FullPageLoader";



function ChangePassword() {
  const buttonRef = useRef(null);
  const [disable, setDisable] = React.useState(false);

  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const { users } = useContext(AppContext);

  //   useEffect(() => {
  //     if (users.length == 1 && users[0] !== null) {
  //     }
  //   }, []);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const [loader, showLoader, hideLoader] = useFullPageLoader();


  const { dispatchUserEvent } = useContext(AppContext);
  const handleSubmit = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      showLoader();

     
      // console.log(form);
      //   form["device_type"] = "web";
      //   form["device_token"] = "adaweqwwqewqeqewqeqweqweqweqweq231sdsdfdsfewfrew";
      API_SERVICE.ChangePasswordHome(form)
        .then(({ data }) => {
          buttonRef.current.disabled = false;
          hideLoader();
          if (data.status == true) {
            navigate("/anchor-locations");
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
          // console.log(data)
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };
  const findFormErrors = () => {
    const { password, new_password, old_password } = form;
    const newErrors = {};
    if (!new_password || new_password === "")
      newErrors.new_password = "Enter confirm password";
    if (!old_password || old_password === "")
      newErrors.old_password = "Enter current Password";
    if (!password || password === "") newErrors.password = "Enter password";
    if (new_password !== password)
      newErrors.new_password = "Password and confirm password does not match ";

    return newErrors;
  };

  const [passwordType, setPasswordType] = useState("password");
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);

  const [isRevealPwd3, setIsRevealPwd3] = useState(false);

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Change Password!</h1>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className="form-group icon-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd1 ? "text" : "password"}
                    placeholder="Enter Current Password"
                    onChange={(e) => setField("old_password", e.target.value)}
                    isInvalid={!!errors.old_password}
                    minLength={6}
                    maxLength={20}
                  />
                  {/* */}
                  <Button
                    variant="link"
                    className={
                      isRevealPwd1 ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                  ></Button>

                  <Form.Control.Feedback type="invalid">
                    {errors.old_password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group icon-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd2 ? "text" : "password"}
                    placeholder="Enter New  Password"
                    onChange={(e) => setField("password", e.target.value)}
                    isInvalid={!!errors.password}
                    minLength={6}
                    maxLength={20}
                  />
                  <Button
                    variant="link"
                    className={
                      isRevealPwd2 ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd2((prevState) => !prevState)}
                  ></Button>

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group icon-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd3 ? "text" : "password"}
                    placeholder="Enter Confirm Password"
                    onChange={(e) => setField("new_password", e.target.value)}
                    isInvalid={!!errors.new_password}
                    minLength={6}
                    maxLength={20}
                  />
                  <Button
                    variant="link"
                    className={
                      isRevealPwd3 ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd3((prevState) => !prevState)}
                  ></Button>

                  <Form.Control.Feedback type="invalid">
                    {errors.new_password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Submit</span>
                </Button>
              </Form>
            </div>
          {loader}

          </Container>
        </section>
      </main>
    </>
  );
}
export default ChangePassword;
