import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import loader from "./../../assets/loader.gif";
import { AppContext } from "../../context";
import "./login.css";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isMobile } from "react-device-detect";
import parameters from "../../parameters.ts";
import { useGeolocated } from "react-geolocated";
import firebase from "firebase/compat/app";
import useFullPageLoader from "../../hooks/FullPageLoader";

function LogIn() {
  const [token, setToken] = React.useState("");

  const messaging = getMessaging();
  getToken(messaging, { vapidKey: parameters.VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("Some Error Get");
    });

  const buttonRef = useRef(null);
  const [disable, setDisable] = React.useState(false);

  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const { users } = useContext(AppContext);
  const { dispatchUserEvent } = useContext(AppContext);

  useEffect(() => {
    if (users) {
      GetScreen();
    } else {
      navigate("/");
    }
  }, [users]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  const [btnloader, setLoader] = useState(false);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      },
      watchPosition: true,
      geolocationProvider: navigator.geolocation,
    });

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(token);
    // if (token && coords) {
    buttonRef.current.disabled = true;
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      showLoader();

      form["device_type"] = isMobile ? "mobile" : "web";
      form["device_token"] = token;
      API_SERVICE.Login(form)
        .then(({ data }) => {
          buttonRef.current.disabled = false;
          hideLoader();
          if (data.data) {
            toast.success(data.message);
            localStorage.setItem("userAuth", JSON.stringify(data.data));
            setDisable(false);
            dispatchUserEvent("ADD_USER", { newUser: data.data });
            if (data.data.is_location_approved) {
              localStorage.setItem("screen", JSON.stringify("true"));
            }
            GetScreen();
          } else {
            setDisable(true);
            toast.error(data.message);
          }
        })
        .catch((e) => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
    // } else {
    //   if (!coords) {
    //     firebase.messaging().deleteToken();

    //     toast.error(
    //       isMobile == "mobile"
    //         ? "Please enable location and notification permision"
    //         : "Please enable location and notification permision"
    //     );
    //   } else {
    //     firebase.messaging().deleteToken();

    //     toast.error(
    //       isMobile == "mobile"
    //         ? "Please enable location and notification permision"
    //         : "Please enable location and notification permision"
    //     );
    //   }
    // }
  };
  const findFormErrors = () => {
    const { username, password } = form;
    const newErrors = {};
    if (!username || username === "") newErrors.username = "Enter username";
    if (!password || password === "") newErrors.password = "Enter password";

    return newErrors;
  };

  const [passwordType, setPasswordType] = useState("password");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Hello Again!</h1>
                <h3>Log in into your account</h3>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className="form-group icon-group username-group"
                  controlId="username"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    onChange={(e) => setField("username", e.target.value)}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group icon-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd ? "text" : "password"}
                    placeholder=" Enter Password"
                    onChange={(e) => setField("password", e.target.value)}
                    isInvalid={!!errors.password}
                  />
                  <Button
                    variant="link"
                    className={
                      isRevealPwd ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  ></Button>

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <p className="forgot-link small-text text-center">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p>
                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Log in</span>
                </Button>

                {}
              </Form>
              <p className="bottom-note text-center">
                Don’t have an account?{" "}
                <Link to="/create-account">
                  <b>Create an account</b>
                </Link>
              </p>
              <p className="forgot-link small-text text-center">
                Version:0.2.2
              </p>
            </div>
            {loader}
          </Container>
        </section>
      </main>
    </>
  );
}
export default LogIn;
