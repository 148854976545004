import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import Otp from "./../otp/index";
// import { hasUserRole } from "../../services/Utility.ts";
import { AppContext } from "../../context";
import useFullPageLoader from "../../hooks/FullPageLoader";



function ForgotPassword() {
  // const result = hasUserRole();
  const { users } = useContext(AppContext);

  useEffect(() => {
    if (users) {
      GetScreen();
    } 
  }, [users]);

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };
  const errStyle = {
    width: "100%",
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#dc3545",
  };
  const [form, setForm] = useState({});
  const [form1, setForm1] = useState({});
  const [form2, setForm2] = useState({});

  const [errors1, setErrors1] = useState({});
  const [errors2, setErrors2] = useState({});

  const [errors, setErrors] = useState({});
  const [EmailData, setEmailData] = useState(true);
  const [Email, setEmail] = useState(true);
  const [OTP, setOTP] = useState(false);
  const [Password, setPassword] = useState(false);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const setOTPInput = (field, value) => {
    setForm1({
      ...form1,
      [field]: value,
    });
    if (!!errors1[field])
      setErrors1({
        ...errors1,
        [field]: null,
      });
  };
  const setPasswordInput = (field, value) => {
    setForm2({
      ...form2,
      [field]: value,
    });
    if (!!errors2[field])
      setErrors2({
        ...errors2,
        [field]: null,
      });
  };

  const buttonRef = useRef(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  
  const handleSubmit = (e) => {
    e.preventDefault();
    buttonRef.current.disabled = true;

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      showLoader();

      
      API_SERVICE.ForgotPassword(form)
        .then(({ data }) => {
          buttonRef.current.disabled = false;
          hideLoader();
          if (data.status == true) {
            toast.success(data.message);
            setEmailData(data.data);
            setEmail(false);
            setOTP(true);
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const findFormErrors1 = () => {
    const { otp } = form1;
    const newErrors1 = {};
    if (!otp || otp === "") newErrors1.otp = "Enter otp";
    // console.log(newErrors1.otp);
    return newErrors1;
  };

  const findFormErrors2 = () => {
    const { password, confirm_password } = form2;
    const newErrors2 = {};
    if (!password || password === "") newErrors2.password = "Enter password";
    if (!confirm_password || confirm_password === "")
      newErrors2.confirm_password = "Enter password";
    if ((confirm_password !== password) === "")
      newErrors2.confirm_password =
        "Password and confirmation password does not match ";

    return newErrors2;
  };
  const buttonRef1 = useRef(null);

  const handleOTP = (e) => {
    e.preventDefault();
    const newErrors1 = findFormErrors1();
    buttonRef1.current.disabled = true;
    form1["email"] = form.email;
    if (Object.keys(newErrors1).length > 0) {
      setErrors1(newErrors1);
      buttonRef1.current.disabled = false;
    } else {
      showLoader();
      API_SERVICE.OTPVerify(form1)
        .then(({ data }) => {
          buttonRef1.current.disabled = false;
          hideLoader();

          if (data.status == true) {
            toast.success(data.message);
            setOTP(false);
            setPassword(true);
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const buttonRef2 = useRef(null);

  const navigate = useNavigate();
  const handlePassword = (e) => {
    buttonRef2.current.disabled = true;

    e.preventDefault();
    form2["token"] = EmailData.token;
    const newErrors2 = findFormErrors2();
    if (Object.keys(newErrors2).length > 0) {
      setErrors2(newErrors2);
      buttonRef2.current.disabled = false;
    } else {
      showLoader();
      API_SERVICE.ChangePassword(form2)
        .then(({ data }) => {
          buttonRef2.current.disabled = false;
          hideLoader();

          if (data.status == true) {
            toast.success(data.message);
            navigate("/");
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const findFormErrors = () => {
    const { email } = form;
    const newErrors = {};
    if (!email || email === "") newErrors.email = "Enter email";

    return newErrors;
  };
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          {Email && (
            <Container>
              <div className="mobile-logo d-block d-md-none text-center">
                <img src={logoBig} alt="logo" />
              </div>
              <div className="auth-block">
                <div className="head text-center">
                  <h1>Forgot Password</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    className="form-group icon-group username-group"
                    controlId="username"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter email"
                      onChange={(e) => setField("email", e.target.value)}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <p className="forgot-link small-text text-center">
                    <Link to="/">Back To Login</Link>
                  </p>

                  <Button type="submit" className="theme-btn" ref={buttonRef}>
                    <span>Forgot Password</span>
                  </Button>
                </Form>
              </div>
          {loader}

            </Container>
          )}
          {OTP && (
            <Container>
              <div className="mobile-logo d-block d-md-none text-center">
                <img src={logoBig} alt="logo" />
              </div>
              <div className="auth-block">
                <div className="head text-center">
                  <h3>Verify your OTP</h3>
                </div>
                <Form onSubmit={handleOTP}>
                  <Form.Group className="form-group" controlId="username1">
                    <Form.Control
                      type="text"
                      className="text-center"
                      placeholder="Verify OTP"
                      onChange={(e) => setOTPInput("otp", e.target.value)}
                      isInvalid={!!errors1.otp}
                    />
                  </Form.Group>
                  <Form.Control.Feedback
                    type="invalid-feedback"
                    style={errStyle}
                  >
                    {errors1.otp}
                  </Form.Control.Feedback>
                  <p
                    className="forgot-link small-text text-center"
                    onClick={handleSubmit}
                  >
                    Resend OTP
                  </p>
                  <Button type="submit" className="theme-btn" ref={buttonRef1}>
                    <span>Submit</span>
                  </Button>
                </Form>
              </div>
          {loader}

            </Container>
          )}
          {Password && (
            <Container>
              <div className="mobile-logo d-block d-md-none text-center">
                <img src={logoBig} alt="logo" />
              </div>
              <div className="auth-block">
                <div className="head text-center">
                  <h3>Change your Password</h3>
                </div>
                <Form onSubmit={handlePassword}>
                  <Form.Group
                    className="form-group icon-group password-group"
                    controlId="password"
                  >
                    <Form.Control
                      type={isRevealPwd ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) =>
                        setPasswordInput("password", e.target.value)
                      }
                      isInvalid={!!errors2.password}
                    />
                    <Button
                      variant="link"
                      className={
                        isRevealPwd ? "password-icon active" : "password-icon"
                      }
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    ></Button>
                    <Form.Control.Feedback type="invalid12" style={errStyle}>
                      {errors2.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group icon-group password-group"
                    controlId="username"
                  >
                    <Form.Control
                      type={isRevealPwd1 ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={(e) =>
                        setPasswordInput("confirm_password", e.target.value)
                      }
                      isInvalid={!!errors2.confirm_password}
                    />
                    <Button
                      variant="link"
                      className={
                        isRevealPwd1 ? "password-icon active" : "password-icon"
                      }
                      onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                    ></Button>
                    <Form.Control.Feedback type="invalid21" style={errStyle}>
                      {errors2.confirm_password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button type="submit" className="theme-btn" ref={buttonRef2}>
                    <span>Change Password</span>
                  </Button>
                </Form>
              </div>
          {loader}

            </Container>
          )}
        </section>
      </main>
    </>
  );
}
export default ForgotPassword;
