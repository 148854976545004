import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate,Link } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import { AppContext } from "../../context";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import SupportChat from "../../components/supportChat";
import {
  getDatabase,
  ref,
  onValue
} from "firebase/database";
import useFullPageLoader from "../../hooks/FullPageLoader";

function WithDrawan() {
  const buttonRef = useRef(null);

  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState();

  const { users } = useContext(AppContext);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const { dispatchUserEvent } = useContext(AppContext);
  const handleSubmit = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      // if() {
        showLoader();
        
      API_SERVICE.CheckWIthdrawan()
        .then(({ data }) => {
          hideLoader();
          // console.log(data);
          buttonRef.current.disabled = true;
          if (data?.data?.withdraw_request_pending == true) {
            buttonRef.current.disabled = false;
            document.getElementById("create-course-form").reset();
            setNum("")

            toast.error("you have already send one request please wait");
          } else {
            buttonRef.current.disabled = true;
            showLoader();
            API_SERVICE.WithdrawanTransaction({ amount: parseInt(form.amount) })
              .then(({ data }) => {
          hideLoader();

                // console.log(data);
                buttonRef.current.disabled = false;
                if (data.status == true) {
                  toast.success(data.message);
                  document.getElementById("create-course-form").reset();
                  setNum("")
                } else {
                  toast.error(data.message);
                }
                // console.log(data)
              })
              .catch(() => {
                toast.success({ message: API_SERVICE.handleErrors(e) });
              });
          }
          // console.log(data)
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
      // }
    }
  };

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  const findFormErrors = () => {
    const { amount } = form;
    const newErrors = {};
    if (!amount || amount == "") newErrors.amount = "Enter Amount";
    if (amount == "0") newErrors.amount = "Amount Should Be Greater Than 0";
    if (containsSpecialChars(amount)) {
      newErrors.amount = "Enter Valid Amount";
    }
    if (amount > userData?.current_balance)
      newErrors.amount = "Amount Should Less of your current balance";

    return newErrors;
  };

  // const findFormErrors1 = () => {
  //   const { amount } = form;
  //   const newErrors1 = {};
  //   if (amount.length > 5) newErrors1.amount = "Amount Reched";
  //   return newErrors1;
  // };

  const user = localStorage.getItem("userAuth");
  const firebase_key = JSON.parse(user).firebase_key;
  useEffect(() => {
    const db = getDatabase();
    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
    });
  }, []);

  const [num, setNum] = useState("");
  const limit = 4;
  const handleNumChange = (event) => {
    // console.log(event.target.value.slice(0, limit));
    setNum(event.target.value.slice(0, limit));
    setField("amount", event.target.value.slice(0, limit));
  };

  return (
    <>
     <Button className="back-btn d-block d-md-none" variant="link">
        <Link to="/anchor-locations">
          <img src={backBtn} alt="menu" />
        </Link>
      </Button>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center" style={{"marginBottom":"20px"}}>
                <h1>Request Withdrawal</h1>
              </div>
              <Form onSubmit={handleSubmit} id="create-course-form">
                <h3>
                  Total Balance : $
                  {userData?.current_balance ? userData?.current_balance : 0}{" "}
                </h3>

                <Form.Group
                  className="form-group"
                  controlId="additionalInformation"
                >
                  <Form.Control
                    value={num}
                    type="number"
                    placeholder="Enter Amount"
                    // onChange={(e) => setField("amount", e.target.value)}
                    isInvalid={!!errors.amount}
                    onChange={handleNumChange}
                  />
                  {/* <Form.Control
                    // className="form-group"
                    type="number"
                    // id="first_name"
                    name="first_name"
                    value={num}
                    onChange={handleNumChange}
                  /> */}

                  <Form.Control.Feedback type="invalid">
                    {errors.amount}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Submit</span>
                </Button>
              </Form>
            </div>
        {loader}

          </Container>
        </section>
      </main>
      <SupportChat />

    </>
  );
}
export default WithDrawan;
