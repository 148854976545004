// import firebase from "firebase"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getDatabase } from "firebase/database";
// import "firebase/messaging";
import { getMessaging, onMessage } from "firebase/messaging";
// getToken
import 'firebase/compat/messaging';
import parameters from "./parameters.ts";


const firebaseApp = firebase.initializeApp({
    apiKey: parameters.apiKey,
    authDomain: parameters.authDomain,
    projectId: parameters.projectId,
    storageBucket: parameters.storageBucket,
    messagingSenderId: parameters.messagingSenderId,
    appId: parameters.appId,
    measurementId: parameters.measurementId,
    databaseURL: parameters.databaseURL
  })

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db,auth }

function isIOS() {
  const browserInfo = navigator.userAgent.toLowerCase();
  
  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true;
  }
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    return true;
  } 
  return false;
}


export const requestFirebaseNotificationPermission = () =>

  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });


// function requestPermission() {
//   // console.log("Requesting permission...");
//   if (isIOS()) return;

//    return Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       // console.log("Notification permission granted.");
//       const app = firebaseApp;
//       const messaging = getMessaging(app);
//       getToken(messaging, {
//         vapidKey:
//           "BGkL31WfaRmy3MoT-tOoHpWzjAEcQazME0a48HgvHw5WrkjRuBsBTxZd-ue5OZAvOgsZDtRb9JMW7f7Y3RURQVc",
//       }).then((currentToken) => {
//         if (currentToken) {
//           // console.log("currentToken: ", currentToken);
//         } else {
//           // console.log("Can not get token");
//         }
//       });
//     } else {
//       // console.log("Do not have permission!");
//     }
//   });
// }


// export const getToken = async (setTokenFound) => {
//   let currentToken = "";

//   try {
//     currentToken = await messaging.getToken({ vapidKey: "BGkL31WfaRmy3MoT-tOoHpWzjAEcQazME0a48HgvHw5WrkjRuBsBTxZd-ue5OZAvOgsZDtRb9JMW7f7Y3RURQVc" });
//     // currentToken = await messaging.getToken({ vapidKey: "BBQWvfb1z4HtcjF-qd_O5Lt9dgQsCCqXD4g-n8fvUzKvnIkmVskYG4S9pDMHOZV6CievKUSi4c9dTx_4KaJLI2A" });

//     if (currentToken) {
//       setTokenFound(true);
//       // console.log(currentToken)
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }

//   return currentToken;
// };

// requestPermission()

const messaging = firebase.messaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
      // console.log(payload)
    });
    
  });





