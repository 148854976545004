import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Offcanvas } from "react-bootstrap";
import API_SERVICE from "../../services/api-service.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import menuIcon from "./../../assets/images/icon/menu-icon.svg";
import menulogo from "./../../assets/images/daizy-chain-bg-logo.png";
import menuText from "./../../assets/images/icon/menu-text.png";
import { AppContext } from "../../context";
import firebase from 'firebase/compat/app';

function MobileMenu() {
  const { users } = useContext(AppContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const LogOut = (event: any, item: any) => {
    firebase.messaging().deleteToken()
    setActive(6);
    const user1 = localStorage.getItem("userAuth");
    const token = {};
    token["token"] = JSON.parse(user1).token;
    API_SERVICE.LogOut(token)
      .then(({ data }) => {
        localStorage.removeItem("userAuth");
        if (data.status == true) {
          toast.success(data.message);
          // window.location.reload();
          navigate("/");
        } else {
          toast.error(data.message);
          window.location.reload();
        }
      })
      .catch((e) => {
        toast.success({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const [isActive, setActive] = useState(1);

  const toggleClass = (id) => {
    setActive(id);
  };
  const [anchor, setAnchor] = useState(false);

  useEffect(() => {
    // if (users) {
    // GetScreen();
    // }
  }, []);

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.is_location_created == true) {
        if (data.is_busin_profi_created == true) {
          if (data.is_busin_profi_created == true) {
            if (data.is_busin_profi_appr == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/request-sending");
          }
        } else {
          navigate("/location-list");
        }
      } else if (data.is_location_created == false) {
        navigate("/location-registration");
      }
    });
  };

  return (
    <div className="mobile-menu">
      <Button
        className="menu-btn d-block d-md-none"
        variant="link"
        onClick={handleShow}
      >
        <img src={menuIcon} alt="menu" />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <img src={menuText} alt="menu" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {/* <li><Link className={isActive == 1 ? "active" : null} onClick={() => toggleClass(1)} >Home</Link></li> */}
            {/* <li><Link className={isActive == 2 ? "active" : null}onClick={() => toggleClass(2)}>Live Support</Link></li> */}
            {/* <li> */}
              <Link
                className={isActive == 3 ? "active" : null}
                onClick={() => toggleClass(3)}
                to="/faq"
              >
                FAQs
              </Link>
            {/* </li> */}
            {/* to="/faq" */}
            {/* <li><Link className={isActive == 4 ? "active" : null} onClick={() => toggleClass(4)} >Career</Link></li>
            // <li><Link className={isActive == 5 ? "active" : null}  onClick={() => toggleClass(5)}>Delivery Policy</Link></li> */}
            {/* {users &&  (
              <Link className="nav-link" to="/anchor-locations">
                Anchor Locations
              </Link>   
                
              
            ) } */}
            {users ? (
              <Link className="nav-link" to="/anchor-locations">
                Anchor Locations
              </Link>
            ) : null}
            {/* {anchor &&  (
              <Link className="nav-link" to="/anchor-locations">
                Anchor Locations
              </Link>
            )} */}
            {!users && (
              <Link className="nav-link" to="/">
                Log in
              </Link>
            )}
            {!users && (
              <Link className="nav-link" to="/create-account">
                Sign Up
              </Link>
            )}
            {users && (
              <Link className="nav-link" to="/notifications">
                Notifications
              </Link>
            )}
             {users && (
              <Link className="nav-link" to="/transactions">
                Transactions
              </Link>
            )}
             {users && (
              <Link className="nav-link" to="/profile">
                Profile
              </Link>
            )}
             {users && (
              <Link className="nav-link" to="/withdrawals">
                Request Withdrawal
              </Link>
            )}
            {users && (
              // path="/notifications"
              <Link
                className={isActive == 6 ? "active" : null}
                onClick={LogOut}
              >
                Log Out
              </Link>
            )}

            {/* <li></li> */}
          </ul>
        </Offcanvas.Body>
        <div className="menu-bootom text-center">
          <img src={menulogo} alt="logo" />
        </div>
      </Offcanvas>
    </div>
  );
}
export default MobileMenu;
