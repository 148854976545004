import React, { useState, useEffect, useContext, useRef } from "react";
import API_SERVICE from "../../services/api-service.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { AppContext } from "../../context";
import logoBig from "./../../assets/images/logo-big.png";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import useFullPageLoader from "../../hooks/FullPageLoader";


    

function CreateAccount() {
const [loader, showLoader, hideLoader] = useFullPageLoader();

  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      form["device_type"] = "web";
  showLoader();

      API_SERVICE.SignUp(form)
        .then(({ data }) => {
          buttonRef.current.disabled = false;
  hideLoader();

          if (data.status == true) {
            toast.success(data.message);
            navigate("/");
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };
  const findFormErrors = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      mobile_no,
      password,
      confirm_password,
    } = form;
    const newErrors = {};
    if (!first_name || first_name === "")
      newErrors.first_name = "Enter  firstname";
    if (!last_name || last_name === "") newErrors.last_name = "Enter  lastname";
    if (!username || username === "") newErrors.username = "Enter  username";
    if (!email || email === "") newErrors.email = "Enter email ";

    if (!mobile_no || mobile_no === "")
      newErrors.mobile_no = "Enter  mobile number ";
    // if (mobile_no.length > 15) newErrors.mobile_no = "Enter valid phone number";
    if (!password || password === "") newErrors.password = "Enter  password";
    if (!confirm_password || confirm_password === "")
      newErrors.confirm_password = "Enter confirm password ";
    if (confirm_password !== password)
      newErrors.confirm_password =
        "Password and confirm password does not match ";

    return newErrors;
  };

  const { users } = useContext(AppContext);

  useEffect(() => {
    if (users) {
      GetScreen();
    }
  }, [users]);

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);


  const [num, setNum] = useState("");
  const limit = 15;
  const handleNumChange = (event) => {
    // console.log(event.target.value.slice(0, limit));
    setNum(event.target.value.slice(0, limit));
    setField("mobile_no", event.target.value.slice(0, limit));
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section create-account-section">
          <Button className="back-btn d-block d-md-none" variant="link">
            <Link to="/">
              {" "}
              <img src={backBtn} alt="menu" />
            </Link>
          </Button>

          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Create Account</h1>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    onChange={(e) => setField("first_name", e.target.value)}
                    isInvalid={!!errors.first_name}
                    placeholder="First Name"
                    maxLength={10}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="lastName">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setField("last_name", e.target.value)}
                    isInvalid={!!errors.last_name}
                    maxLength={10}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="username">
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setField("username", e.target.value)}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setField("email", e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="mobileNumber">
                  {/* <Form.Control
                  val={num}
                    type="number"
                    placeholder="Mobile Number"
                    // onChange={(e) => setField("mobile_no", e.target.value)}
                    isInvalid={!!errors.mobile_no}
                    onChange={handleNumChange}
                  /> */}
                   <Form.Control
                    value={num}
                    type="number"
                    placeholder="Mobile Number"
                    // onChange={(e) => setField("amount", e.target.value)}
                    isInvalid={!!errors.mobile_no}
                    onChange={handleNumChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile_no}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setField("password", e.target.value)}
                    isInvalid={!!errors.password}
                  />
                  <Button
                    variant="link"
                    className={
                      isRevealPwd ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  ></Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group password-group"
                  controlId="password"
                >
                  <Form.Control
                    type={isRevealPwd1 ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={(e) =>
                      setField("confirm_password", e.target.value)
                    }
                    isInvalid={!!errors.confirm_password}
                  />
                  <Button
                    variant="link"
                    className={
                      isRevealPwd1 ? "password-icon active" : "password-icon"
                    }
                    onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                  ></Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Create Account</span>
                </Button>
              </Form>
              <p className="bottom-note text-center">
                Already have an account?{" "}
                <Link to="/">
                  <b>Log In</b>
                </Link>
              </p>
            </div>
            
  {loader}
          </Container>
        </section>
      </main>
    </>
  );
}
export default CreateAccount;
