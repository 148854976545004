import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { AppContext } from "../../context";
import API_SERVICE from "../../services/api-service.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./../../assets/images/logo.png";
import { tokenToString } from "typescript";
import BankDetails from "./../../pages/BankDetails/bankDetails";
import "./style.css";
import { getMessaging, deleteToken } from 'firebase/messaging';
import parameters from "../../parameters.ts";
import firebase from 'firebase/compat/app';
import Transaction from './../../pages/Transactions/index';
import useFullPageLoader from "../../hooks/FullPageLoader";


function MainHeader() {
  const { users } = useContext(AppContext);
  const { dispatchUserEvent } = useContext(AppContext);
  const navigate = useNavigate();


const firebaseApp = firebase.initializeApp({
    apiKey: parameters.apiKey,
    authDomain: parameters.authDomain,
    projectId: parameters.projectId,
    storageBucket: parameters.storageBucket,
    messagingSenderId: parameters.messagingSenderId,
    appId: parameters.appId,
    measurementId: parameters.measurementId,
    databaseURL: parameters.databaseURL
  })
  const messaging = getMessaging(firebaseApp);

const [loader, showLoader, hideLoader] = useFullPageLoader();

  const LogOut = () => {
    firebase.messaging().deleteToken()

    const user = localStorage.getItem("userAuth");
    const token = {};
    token["token"] = JSON.parse(user).token;
    // showLoader();
    
    API_SERVICE.LogOut(token)
      .then(({ data }) => {
        // hideLoader();
        localStorage.removeItem("userAuth");
        localStorage.removeItem("screen");
        // firebase.messaging().deleteToken()
        if (data.status == true) {
          dispatchUserEvent("LOGOUT_USER", { newUser: null });
          navigate("/");
          window.location.reload();
        } else {
          navigate("/");
          window.location.reload();
        }
      })
      .catch((e) => {
        toast.success({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const ChangePassword = () => {
    navigate("/change-password");
  };
  const Profile = () => {
    navigate("/profile");
  };
  const BankDetails = () => {
    navigate("/bank-detail");
  };

  const Transactions = () => {
    navigate("/transactions");
  };

  const Withdrawan = () => {
    navigate("/withdrawals");

  };


  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    // showLoader()
    API_SERVICE.GetScreen().then(({ data }) => {
      // hideLoader()
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  return (
    <header>
      <Container>
        <Navbar expand="md">
          <Navbar.Brand>
            {/* <div className="container">
              <img
                src={logo}
                alt="logo"
                className="image"
                onClick={GetScreen}
              />
            </div> */}
            <Link >
              <img src={logo} alt="logo" onClick={GetScreen}/>
            
            </Link>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* <Link className="nav-link" >Home</Link>s */}
              {users && (
                <Link className="nav-link" to="/notifications">
                Notifications
              </Link>
              )}
              <Link className="nav-link" to="/faq">
                FAQs
              </Link>
              {/* <Link className="nav-link">Career</Link> */}
              <Link className="nav-link">Delivery Policy</Link>
              {!users && (
                <Link className="nav-link" to="/">
                  Log in
                </Link>
              )}
              {!users && (
                <Link className="nav-link" to="/create-account">
                  Sign Up
                </Link>
              )}
              {users && (
                <NavDropdown
                  title={Capitalize(users?.username)}
                  id="basic-nav-dropdown"
                  align="end"
                >
                  <NavDropdown.Item onClick={Profile}>Profile</NavDropdown.Item>
                  <NavDropdown.Item onClick={BankDetails}>
                    Bank Details
                  </NavDropdown.Item>

                  <NavDropdown.Item onClick={ChangePassword}>
                    Change password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={Transactions}>
                    Transactions
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={Withdrawan}>
                  
                  Request Withdrawal
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item>Account</NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={LogOut}>Log Out</NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    {loader}

      </Container>
    </header>
  );
}
export default MainHeader;
