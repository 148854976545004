import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AppContext } from "./context";
import { Redirect } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "./components/header";
import LogIn from "./pages/logIn";
import CreateAccount from "./pages/createAccount";
import LocationRegistration from "./pages/locationRegistration";
import LocationEdit from "./pages/locationRegistration/locationEdit";
import RequestSending from "./pages/requestSending";
import RequestApproved from "./pages/requestApproved";
// import AnchorLocations from "./pages/anchorLocations";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import Otp from "./pages/otp";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoutes";
import PageNOtFound from "./pages/PageNotFound/PageNotFound";
import DeliveryPolicy from "./pages/DeliveryPolicy/DeliveryPolicy";
import Faq from "./pages/Faq/Faq";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ChangePassword from "./pages/changePassword/changePassword";
import Profile from "./pages/Profile/profile";
import BankDetails from "./pages/BankDetails/bankDetails";
import BusinessList from "./pages/BusinessList";
import BusinessList2 from "./pages/BusinessList/businesslist2";
// import firebase from "firebase/compat/app";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onMessageListener } from "./firebase";
import "./firebase";
import AnchorLocationsPayment from "./pages/anchorLocationsPayment";
import BusinessReqSent from "./pages/businessRequestSent/index";
import LocationRegistrationSecond from "./pages/locationRegistration/locationaddSecond";
import Notifications from "./components/Notification/Notification";
import NoInternetConnect from "./components/NoInternetConnet/NoInternetConnect";
import { useGeolocated } from "react-geolocated";
import LocationOrders from "./pages/anchorLocationsPayment/LocationOrders";
import Notifications1 from "./pages/Notifications";
import Transaction from "./pages/Transactions";
import WithDrawan from './pages/withdrawan/withDrawan';
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  child,
  orderByChild,
  update,
  connectDatabaseEmulator,
} from "firebase/database";
import { transpile } from 'typescript';

function App() {
  const [users, setUsers] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      },
      watchPosition: true,
      geolocationProvider: navigator.geolocation,
      // userDecisionTimeout: 5000,
    });

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);

    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem("userAuth"));
    if (userAuth) {
      setUsers(userAuth);
    }
  }, []);

  useEffect(() => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  const dispatchUserEvent = (actionType, payload) => {
    switch (actionType) {
      case "ADD_USER":
        setUsers(payload.newUser);
        return;
      case "LOGOUT_USER":
        setUsers(payload.newUser);
        return;
      default:
        setUsers(null);
        return;
    }
  };

  const [show, setShow] = useState(false);
  const [notification1, setNotification1] = useState({ title: "", body: "" });

  onMessageListener()
    .then((payload) => {
      // console.log(payload)
      setShow(true);
      setNotification1({
        title: payload.data.title,
        body: payload.data.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const MINUTE_MS = 60000;
  const user = localStorage.getItem("userAuth");

  // useEffect(() => {
  //   const db = getDatabase();
  //   const firebase_key = JSON.parse(user)?.firebase_key;
  //   const getData1 = ref(db, `dz_users/${firebase_key}`);
  //   var sample_arr = [];
  //   {
  //     users
  //       ? onValue(getData1, (snapshot) => {
  //           var array = snapshot.val()?.order_key.split(",");
  //           var array1 = snapshot.val()?.order_passthrough_key?.split(",");
  //           if (array[0] !== "" && array1[0] !== "") {
  //             for (let i = 0; i < array?.length; i++) {
  //               for (let i = 0; i < array1?.length; i++) {
  //                 sample_arr[i] = {
  //                   orderKey: array[i],
  //                   passThruKey: array1[i],
  //                 };
  //               }
  //             }
  //           }

  //           var locations = {
  //             realtime_lattitude: coords?.latitude,
  //             realtime_longitude: coords?.longitude,
  //           };
  //           var logs = {
  //             timestamp:Date.now(),
  //             realtime_lattitude: coords?.latitude,
  //             realtime_longitude: coords?.longitude,
  //           };
  //           const interval = setInterval(() => {
  //             if (sample_arr.length !== 0) {
  //               sample_arr?.forEach((item1) => {
  //                 const getData1 = ref(
  //                   db,
  //                   `dz_orders/${item1.orderKey}/order_passthrough/${item1.passThruKey}`
  //                 );
  //                 const getData = ref(
  //                   db,
  //                   `dz_orders/${item1.orderKey}/order_passthrough/${item1.passThruKey}/location_logs`
  //                 );
  //                 update(getData1, locations).then(() =>
  //                   console.log("success")
  //                 );
  //                 push(getData, logs).then(() =>
  //                   console.log("success")
  //                 );
  //               });
  //             }
  //             else {
  //               console.log("null");
  //             }
  //           }, MINUTE_MS);
  //           return () => clearInterval(interval);
  //         })
  //       : (sample_arr = []);
  //   }
  // }, [user]);

  return (
    <>
      <NoInternetConnect>
        <AppContext.Provider value={{ users, dispatchUserEvent }}>
          <Notifications />
          <Router>
            <MainHeader />
            <Routes>
              <Route exact path="/" element={<LogIn />}></Route>
              <Route path="/create-account" element={<CreateAccount />}></Route>
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route exact path="/" element={<ProtectedRoute />}>
                <Route
                  exact
                  path="/location-registration"
                  element={<LocationRegistration />}
                />
                <Route
                  path="/location-registration-add"
                  element={<LocationRegistrationSecond />}
                />
                <Route
                  exact
                  path="/request-sending"
                  element={<RequestSending />}
                />
                <Route
                  exact
                  path="/request-approved"
                  element={<RequestApproved />}
                />
                <Route
                  exact
                  path="/change-password"
                  element={<ChangePassword />}
                />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/bank-detail" element={<BankDetails />} />

                {/* <Route
                path="/anchor-locations"
                element={<AnchorLocations />}
              ></Route> */}
                <Route
                  path="/anchor-locations"
                  element={<AnchorLocationsPayment />}
                ></Route>
                <Route
                  path="/view-orders/:id"
                  element={<LocationOrders />}
                ></Route>
                <Route
                  path="/notifications"
                  element={<Notifications1 />}
                ></Route>
                <Route
                  path="/transactions"
                  element={<Transaction />}
                ></Route>
                 <Route
                  path="/withdrawals"
                  element={<WithDrawan />}
                ></Route>
                <Route
                  path="/location-registration/edit/:id"
                  element={<LocationEdit />}
                ></Route>

                {/* <Route
                exact
                path="/anchor-locations-payment"
                element={<AnchorLocationsPayment />}
              /> */}

                <Route
                  exact
                  path="/request-sent"
                  element={<BusinessReqSent />}
                />
                <Route path="/location-list/:id" element={<BusinessList2 />} />
                <Route path="/location-list" element={<BusinessList />} />
              </Route>
              <Route exact path="/otp" element={<Otp />}></Route>
              <Route path="*" element={<PageNOtFound />} />
              <Route path="/faq" element={<Faq />} />
              <Route
                exact
                path="/delivery-policy"
                element={<DeliveryPolicy />}
              />
              <Route exact path="/verify-email" element={<VerifyEmail />} />
            </Routes>
            <ToastContainer />
          </Router>
        </AppContext.Provider>
      </NoInternetConnect>
    </>
  );
}

export default App;
