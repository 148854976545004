import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import delivered from "./../../assets/images/icon/right-icon.svg";
import rightArrow from "./../../assets/images/icon/right-arrow.svg";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "../../services/api-service.js";
import { AppContext } from "../../context";
import useFullPageLoader from "../../hooks/FullPageLoader";


function VerifyEmail() {

  const { search } = useLocation();
  const navigate = useNavigate();


const parameters = new URLSearchParams(search);
const [message, setMessage] = useState();
const [status, setStatus] = useState(false);



const email_verification_code = parameters.get('code');
const email = parameters.get('email');



const [loader, showLoader, hideLoader] = useFullPageLoader();

    useEffect(() => {
        getFaq();
      }, []);

      const getFaq = () => {
        const payload ={}
        payload["email_verification_code"] = email_verification_code
        payload["email"] = email

        showLoader();
       
        API_SERVICE.VerifyEmail(payload).then(({ data }) => {
          hideLoader();
            if(data.status == true){
                setMessage(data.message)
                setStatus(true)
            }else{
                setMessage(data.message)
            }
        
        });
      };

  return (
    <>
      {/* <MobileMenu /> */}
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
          <div className="auth-block">
          {/* style={{width:"1100px"}} */}
              <div className="head text-center">
                <h1>{message}</h1>
                {/* <h3>Log in into your account</h3> */}
              </div>
              {/* <p className="forgot-link small-text text-center">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p> */}
                {status ?  
                <Button type="submit" className="theme-btn justify-content-center" >
                <Link to="/">
                  <span>Back To Login</span>
                  </Link>
                </Button> 
                  : null}  

                {/* <Row md={10} xl={4} className="justify-content-center">
                  <Button
                    className="theme-btn"
                    style={{ width: "300px" }}
                    onClick={handleSubmit}
                    ref={buttonRef}
                  >
                    <span>Submit</span>
                  </Button>
                </Row> */}
               
            </div>
          {loader}
           
          </Container>
        </section>
      </main>
      {/* <SupportChat /> */}
    </>
  );
}
export default VerifyEmail;
