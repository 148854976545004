import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import delivered from "./../../assets/images/icon/right-icon.svg";
import rightArrow from "./../../assets/images/icon/right-arrow.svg";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "../../services/api-service";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import parameters from "./../../parameters.ts";
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  child,
  orderByChild,
  update,
  connectDatabaseEmulator,
} from "firebase/database";
// import moment from 'moment';
import  DateFormat  from "./../../services/Utility.ts";
import useFullPageLoader from "../../hooks/FullPageLoader";



function Notifications() {
  let { id } = useParams();

  const [data, setData] = useState();

  const getData = () => {
    var pages = {
      page: currPage,
      per_page: 10,
    };
    API_SERVICE.AllNotifications(pages).then(({ data }) => {
      setData(data?.data);
      // console.log(data)
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const final = date.toLocaleString(navigator.language, {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return final;
  };

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  useEffect(() => {
    // getData();
    var pages = {
      page: currPage,
      per_page: 10,
    };
    const fetchData = async () => {
      showLoader();

      
      await API_SERVICE.AllNotifications(pages).then(({ data }) => {
        hideLoader();
        setData(data?.data);
        if (!data.data.data.length) {
          setLastList(true);
          return;
        }
        setPrevPage(currPage);
        setUserList([...userList, ...data?.data?.data]);
      });
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, lastList, prevPage, userList]);

  const user = localStorage.getItem("userAuth");
  const firebase_key = JSON.parse(user).firebase_key;

  const [orderData, setOrderData] = useState();
  const [userData, setUserData] = useState();
  const [orderDetail, setDetails] = useState();
  const [results, setResults] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);

  const [orderkey, setOrderKey] = useState([]);
  const [realData, setRealData] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
      var array = snapshot.val()?.order_key.split(",");
      setOrderKey(array);
      // console.log(array)
      const orders = [];
      const categories = [];
      const allUnique = [];
      // console.log(array)
      if (array) {
        // const all = ["-NKDjSGLPYzDtgGS5WnS", "-NKDde68PZujYJZmtQHt"];
        array.forEach((item1) => {
          const getData1 = ref(db, `dz_orders/${item1}/notification`);
          onValue(getData1, (snapshot2) => {
            snapshot2.forEach((childSnapshot) => {
              categories.push(childSnapshot.val());
              categories.unshift(categories.pop());
            });
            setRealData(categories);
           
          });
        });

        return false;
      }
    });
  }, []);

  



  // console.log(realData)
  return (
    <>
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <Button className="back-btn d-block d-md-none" variant="link">
          <Link to="/anchor-locations">
            <img src={backBtn} alt="menu" />
          </Link>
        </Button>
        <main className="main-section">
          <section className="common-section auth-section anchor-locations">
            <div className="d-block d-md-none text-center">
              <img src={textLogo} alt="arrow" />
            </div>
            <Container>
              <div className="auth-block" style={{ width: "884px" }}>
                <Row className="">
                  <Col
                    sm={7}
                    className="right-block"
                    style={{ borderLeft: "none", width: "100%" }}
                  >
                    <div className="head d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Notifications List</h4>
                    </div>

                    <div className="report-list">
                      {realData.map((obj, index) => (
                        <div className="item mb-2" key={index}>
                          <div
                            className="head-bar d-flex justify-content-between"
                            style={{ fontSize: "13px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <p className="ids">
                                <b>{obj?.title}</b>
                              </p>
                            </div>
                            <p className="date-time">
                              {/* {formatDate(obj?.created_at)} */}
                              {/* {moment(obj?.created_at).format("MM/DD/YYYY hh:mm A")} */}
                              {DateFormat(obj?.created_at)}

                            </p>
                          </div>
                          <div
                            className="pick-drop d-flex"
                            style={{ fontSize: "13px" }}
                          >
                            <div>
                              <p>{obj?.description}</p>
                              {/* <p>
                                {obj?.notification_type} &nbsp; #{obj?.order_no}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="report-list">
                      {userList.map((obj, index) => (
                        <div className="item mb-2">
                          <div
                            className="head-bar d-flex justify-content-between"
                            style={{ fontSize: "13px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <p className="ids">
                                <b>{obj?.title}</b>
                              </p>
                            </div>
                            <p className="date-time">
                              {/* {formatDate(obj?.created_at)} */}
                              {/* {moment(obj?.created_at).format("MM/DD/YYYY hh:mm A")} */}
                              {DateFormat(obj?.created_at)}

                            </p>
                          </div>
                          <div
                            className="pick-drop d-flex"
                            style={{ fontSize: "13px" }}
                          >
                            <div>
                              <p>{obj?.description}</p>
                              {/* <p>
                                {obj?.notification_type} &nbsp; #{obj?.order_no}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div className="report-list"> */}
                      {userList.length == 0 && realData.length == 0  ? <p>No Data Found</p> : null}
                    {/* </div> */}
                  </Col>
                </Row>
              </div>
        {loader}

            </Container>
          </section>
        </main>
        <SupportChat />
      </div>
    </>
  );
}
export default Notifications;
