import React from 'react'
import { Button, Container, Form } from 'react-bootstrap';

import logoBig from "./../../assets/images/logo-big.png"

function PageNOtFound() {
  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1> 404 </h1>
                <h1>Page Not Found </h1>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </>
  )
}
export default PageNOtFound
