import React, {useState, useEffect} from 'react';
import { Button, Container, Form } from 'react-bootstrap';

import logoBig from "./../../assets/images/logo-big.png"

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return(<>
            <main className="main-section">
              <section className="common-section auth-section login-section">
                <Container>
                  <div className="mobile-logo d-block d-md-none text-center">
                    <img src={logoBig} alt="logo" />
                  </div>
                  <div className="auth-block">
                    <div className="head text-center">
                      <h1> No Internet Connection. Please try again later. </h1>
                    </div>
                  
                  </div>
                </Container>
              </section>
            </main>
    </>)
    }
}

export default NoInternetConnection;