import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  child,
  orderByChild,
  update,
  connectDatabaseEmulator,
} from "firebase/database";
import delivered from "./../../assets/images/icon/right-icon.svg";
import rightArrow from "./../../assets/images/icon/right-arrow.svg";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "./../../services/api-service";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import moment from "moment";
import DateFormat from "./../../services/Utility.ts";
import useFullPageLoader from "../../hooks/FullPageLoader";

function LocationOrders1() {
  // useEffect(() => {
  //   getData();
  // }, []);

  let { id } = useParams();

  const [data, setData] = useState();

  // const getData = () => {
  //   var location_id = {
  //     location_id: id,
  //     page:1,
  //     per_page:10
  //   };
  //   API_SERVICE.LocationOrders(location_id).then(({ data }) => {
  //     setData(data?.data);
  //   });
  // };
  // console.log(data);
  const user = localStorage.getItem("userAuth");
  const firebase_key = JSON.parse(user).firebase_key;
  const [orderData, setOrderData] = useState();
  const [userData, setUserData] = useState();
  const [orderDetail, setDetails] = useState();
  const [results, setResults] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);

  const [orderkey, setOrderKey] = useState([]);
  const [passthru, setOrderPassthruKey] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    const db = getDatabase();
    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
      var array = snapshot.val()?.order_key.split(",");
      var array1 = snapshot.val()?.order_passthrough_key?.split(",");
      setOrderKey(array);
      setOrderPassthruKey(array1);
      const orders = [];
      const categories = [];
      if (array) {
        array.forEach((item) => {
          const temp = item;
          const getData = ref(db, `dz_orders/${temp}`);
          onValue(getData, (snapshot1) => {
            setOrderData(snapshot1.val());
            orders.push(snapshot1.val());
            setOrders(orders);
            array1.forEach((item1) => {
              const getData1 = ref(
                db,
                `dz_orders/${temp}/order_passthrough/${item1}`
              );
              onValue(getData1, (snapshot2) => {
                categories.push(snapshot2.val());
                setDetails(snapshot2.val());
              });
              const uniques = Object.values(
                categories.reduce((a, c) => {
                  a[
                    c?.pickup_anchor_location_id +
                      "|" +
                      c?.drop_off_anchor_location_id
                  ] = c;
                  return a;
                }, {})
              );

              const results = uniques.filter((element) => {
                return element !== null;
              });
              const customerName = orders.map(({ customer_name }) => ({
                customer_name,
              }));
              const final = customerName.map(({ customer_name }, i) => ({
                customer_name,
                ...results[i],
              }));
              setResults(final);
            });
          });

          return false;
        });
      }
    });
  }, []);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  useEffect(() => {
    // getData();
    // var pages = {
    //   page: currPage,
    //   per_page: 10,
    // };
    var pages = {
      location_id: id,
      page: currPage,
      per_page: 10,
    };
    const fetchData = async () => {
      showLoader();
     
      await API_SERVICE.LocationOrders(pages).then(({ data }) => {
        hideLoader();
        setData(data?.data);
        // console.log(data)
        if (!data?.data?.order_detail.length) {
          setLastList(true);
          return;
        }
        setPrevPage(currPage);
        setUserList([...userList, ...data?.data?.order_detail]);
      });
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, lastList, prevPage, userList]);
  // console.log(userList);
  // console.log(currPage);
  // console.log(lastList);

  return (
    <>
    <div
        onScroll={onScroll}
        ref={listInnerRef}
        style={{ height: "100vh", overflowY: "auto" }}
      >
      <Button className="back-btn d-block d-md-none" variant="link">
        <Link to="/anchor-locations">
          <img src={backBtn} alt="menu" />
        </Link>
      </Button>
      <main
        className="main-section"
      >
        <section className="common-section auth-section anchor-locations">
          <div className="d-block d-md-none text-center">
            <img src={textLogo} alt="arrow" />
          </div>
          <Container>
            <div className="auth-block" style={{ width: "884px" }}>
              <Row className="">
                <Col
                  sm={12}
                  className="right-block"
                  style={{ borderLeft: "none", width: "100%" }}
                >
                  <div className="head d-flex justify-content-between align-items-center">
                    {/* <h4 className="mb-0">Delivery Report</h4> */}

                    {/* <Button className="small-btn">Add New</Button> */}
                  </div>
                  <div className="head d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">{data?.address}</h4>
                  </div>
                  <div className="report-list ">
                    <div className="head d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">{results.length !== 0 && "OnGoing Orders" }</h4>
                    </div>
                    {/* {results.length == 0 ? (
                      <p>No Data Found</p>
                    ) : null} */}
                    {results?.map((obj, index) => (
                      <>
                        {obj?.pickup_anchor_location_id == id && (
                          <>
                            <div className="item mb-2">
                              <div
                                className="head-bar d-flex justify-content-between"
                                style={{ fontSize: "13px" }}
                              >
                                <div className="d-flex justify-content-between">
                                  <p className="ids">#{obj?.ticket_id}</p>
                                  <p>
                                    Customer&nbsp;: &nbsp;{obj?.customer_name}
                                  </p>
                                </div>
                                <p className="date-time">
                                  {/* {formatDate(obj?.created_at)} */}
                                  {/* {moment(obj?.created_at).format(
                                    "MM/DD/YYYY hh:mm A"
                                  )} */}
                                  {DateFormat(obj?.created_at)}
                                </p>
                              </div>
                              <div className="pick-drop d-flex">
                                <div className="address pick">
                                  <small>PICKUP</small>
                                  <p>{obj?.pickup_location}</p>
                                  <p>
                                    Name :&nbsp;{" "}
                                    {obj?.pickup_anchor_drop_off_name}
                                  </p>
                                </div>
                                <div className="address drop">
                                  <small>DROP OFF</small>
                                  <p>{obj?.drop_off_location}</p>
                                  <p>Name :&nbsp; {obj?.drop_off_name}</p>
                                </div>
                              </div>
                              <div className="bottom-bar d-flex justify-content-between">
                                <p className="delivered d-flex align-items-center">
                                  <img
                                    src={delivered}
                                    alt="delivered"
                                    className="right-icon"
                                  />
                                  {obj?.status == "p"
                                    ? "Pending"
                                    : obj?.status == "pu"
                                    ? "Picked Up"
                                    : obj?.status == "d"
                                    ? "Delivered"
                                    : null}
                                </p>
                                <p>Passthrough: ${obj?.passthrogh_amount}</p>
                              </div>
                            </div>
                          </>
                        ) 
                        // : 
                        // <div
                        //   className="head-bar d-flex justify-content-between"
                        //   style={{ fontSize: "13px", borderBottom: "none" }}
                        // >
                        //   <div
                        //     className="d-flex justify-content-between"
                        //     style={{ borderBottom: "none" }}
                        //   >
                        //     <h5>No Data Found</h5>
                        //   </div>
                        // </div>
                        }
                      </>
                    ))}
                    <div className="head d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Completed Order </h4>
                    </div>
                    {userList.map((obj, index) => (
                      <>
                        {obj?.order_passthough?.map((pass, index) => (
                          <>
                            <div className="item mb-2">
                              <div
                                className="head-bar d-flex justify-content-between"
                                style={{ fontSize: "13px" }}
                              >
                                <div className="d-flex justify-content-between">
                                  <p className="ids">#{pass?.ticket_id}</p>
                                  <p>
                                    Customer&nbsp;: &nbsp;{obj?.customer_name}
                                  </p>
                                </div>
                                <p className="date-time">
                                  {DateFormat(pass?.updated_at)}
                                </p>
                              </div>
                              <div className="pick-drop d-flex">
                                <div className="address pick">
                                  <small>PICKUP</small>
                                  <p>{pass?.pickup_location}</p>
                                  <p>
                                    Name :&nbsp;{" "}
                                    {pass?.pickup_anchor_drop_off_name}
                                  </p>
                                </div>
                                <div className="address drop">
                                  <small>DROP OFF</small>
                                  <p>{pass?.drop_off_location}</p>
                                  <p>Name :&nbsp; {pass?.drop_off_name}</p>
                                </div>
                              </div>
                              <div className="bottom-bar d-flex justify-content-between">
                                <p className="delivered d-flex align-items-center">
                                  <img
                                    src={delivered}
                                    alt="delivered"
                                    className="right-icon"
                                  />
                                  {pass?.status == "p"
                                    ? "Pending"
                                    : pass?.status == "pu"
                                    ? "Picked Up"
                                    : pass?.status == "d"
                                    ? "Delivered"
                                    : null}
                                </p>
                                <p>Passthrough: ${pass?.passthrogh_amount}</p>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ))}

                    {userList.length == 0 && results.length == 0 ? (
                      <p>No Data Found</p>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
        {loader}

          </Container>
        </section>
      </main>
      <SupportChat />
      </div>
    </>
  );
}
export default LocationOrders1;
