/* eslint-disable import/no-anonymous-default-export */
// const API_URL_ONE = "https://daizy-chain.i-nextgen.com/api/"
const API_URL_ONE = "https://admin.daizychain.app/api/"
// const API_URL_ONE = "https://phpstack-763192-3213355.cloudwaysapps.com/api/"
// const API_URL_ONE = "https://admin.flowerpower.nyc/api/daizy/"
const API_URL_TWO = "http://3.109.73.225/capitallever/api/";
const GOOGLE_API_KEY= "AIzaSyABz27cIAvRfGbZtMiHdbFZsEXezLFOjXQ";
// const VAPID_KEY= 'BGkL31WfaRmy3MoT-tOoHpWzjAEcQazME0a48HgvHw5WrkjRuBsBTxZd-ue5OZAvOgsZDtRb9JMW7f7Y3RURQVc';
const VAPID_KEY= 'BBQWvfb1z4HtcjF-qd_O5Lt9dgQsCCqXD4g-n8fvUzKvnIkmVskYG4S9pDMHOZV6CievKUSi4c9dTx_4KaJLI2A';

// const apiKey= "AIzaSyBbesYYaZnaWRwEraiibsXFe02AfP_aOqQ";
// const authDomain= "flowerpowernyc-243b2.firebaseapp.com";
// const projectId = "flowerpowernyc-243b2";
// const storageBucket= "flowerpowernyc-243b2.appspot.com";
// const messagingSenderId= "2239216868";
// const appId= "1:2239216868:web:06820d49654c88e16d00f1";
// const measurementId= "G-57SGLH7ES4";
// const databaseURL= "https://flowerpowernyc-243b2-default-rtdb.firebaseio.com/";
const apiKey= "AIzaSyDPwKR6ZDO4DFoUO_AzTKDhyyNrL3DCG7A";
const authDomain= "flower-345812.firebaseapp.com";
const projectId = "flower-345812";
const storageBucket= "flower-345812.appspot.com";
const messagingSenderId= "716556123577";
const appId= "1:716556123577:web:4a512798c64a3e3bdd0a68";
const measurementId= "G-HYDYH7Y5P3";
const databaseURL= "https://flower-345812-default-rtdb.firebaseio.com/";
export default {
    ApiUrlOne: API_URL_ONE,
    ApiUrlTwo: API_URL_TWO,
    GOOGLE_API_KEY:GOOGLE_API_KEY,
    VAPID_KEY:VAPID_KEY,
    apiKey:apiKey,
    authDomain:authDomain,
    projectId:projectId,
    storageBucket:storageBucket,
    messagingSenderId:messagingSenderId,
    appId:appId,
    measurementId:measurementId,
    databaseURL:databaseURL
};
