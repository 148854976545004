import React, { useState, useContext, useEffect, useRef, Profile } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import { AppContext } from "../../context";
import showPwdImg from "../../assets/images/icon/eye-icon.svg";
import hidePwdImg from "../../assets/images/icon/eye-close-icon.svg";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import useFullPageLoader from "../../hooks/FullPageLoader";



function BankDetails() {
  const buttonRef = useRef(null);
  const [disable, setDisable] = React.useState(false);

  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [bankname, setBankName] = useState("");
  const [accountnumber, setAccountNumber] = useState("");
  const [accholname, setAccountHolName] = useState("");
  const [bankcode, setBankCode] = useState("");

  const [errors, setErrors] = useState({});

  const { dispatchUserEvent } = useContext(AppContext);
  const { users } = useContext(AppContext);
  useEffect(() => {
    GetData();
  }, []);

  const GetData = () => {
    showLoader();
    API_SERVICE.GetProfile().then(({ data }) => {
      hideLoader()
      if (data) {
        setBankName(data.data.bank_name);
        setAccountNumber(data.data.account_number);
        setAccountHolName(data.data.account_holder_name);
        setBankCode(data.data.bank_code);
      }
    });
  };

  const [loader, showLoader, hideLoader] = useFullPageLoader();

     
  const handleSubmit = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;
    } else {
      var payLoad = {
        bank_name: bankname,
        account_number: accountnumber,
        account_holder_name: accholname,
        bank_code: bankcode,
      };
      showLoader();

    
      API_SERVICE.AddBankDetail(payLoad)
        .then(({ data }) => {
          buttonRef.current.disabled = false;
          hideLoader();
          if (data.status == true) {
            toast.success(data.message);
            navigate("/anchor-locations");
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          toast.success({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const findFormErrors = () => {
    const newErrors = {};
    if (!bankname || bankname == "") newErrors.bank_name = "Enter bank name";
    if (!accountnumber || accholname === "")
      newErrors.account_number = "Enter account number";

    if (!accholname || accholname === "")
      newErrors.account_holder_name = "Enter account holder name";
    if (!bankcode || bankcode === "") newErrors.bank_code = "Enter bank code";

    return newErrors;
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section create-account-section">
          <Button className="back-btn d-block d-md-none" variant="link">
            <Link to="/anchor-locations">
              {" "}
              <img src={backBtn} alt="menu" />
            </Link>
          </Button>

          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Bank Details</h1>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    value={bankname}
                    onChange={(e) => setBankName(e.target.value)}
                    isInvalid={!!errors.bank_name}
                    placeholder="Bank Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bank_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="lastName">
                  <Form.Control
                    type="text"
                    value={accountnumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder="Account Number"
                    isInvalid={!!errors.account_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.account_number}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="username">
                  <Form.Control
                    type="text"
                    value={accholname}
                    onChange={(e) => setAccountHolName(e.target.value)}
                    placeholder="Account Holder Name"
                    isInvalid={!!errors.account_holder_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.account_holder_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="email">
                  <Form.Control
                    type="text"
                    value={bankcode}
                    onChange={(e) => setBankCode(e.target.value)}
                    placeholder="Bank Code"
                    isInvalid={!!errors.bank_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bank_code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Submit</span>
                </Button>
              </Form>
            </div>
          {loader}

          </Container>
        </section>
      </main>
    </>
  );
}
export default BankDetails;
