import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import artwork from "./../../assets/images/loc-registration-art.svg";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import API_SERVICE from "./../../services/api-service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  StandaloneSearchBox,
  LoadScript,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import parameters from "../../parameters.ts";
import useFullPageLoader from "../../hooks/FullPageLoader";

function LocationEdit() {
  const navigate = useNavigate();
  const inputRef = useRef();

  const [locationData, setLocationData] = useState(null);
  const [category, setCategory] = useState();
  const [EditData, setEditData] = useState();

  const [career, setCareer] = useState();
  const [Api, setAPI] = useState();
  const [value, setValue] = useState();

  const [state, setState] = React.useState({});
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [CreerId, setCareerId] = useState("");
  const [CategoryId, setCategoryId] = useState();
  const [des, setDescription] = useState();
  const [phonno, setPhoneNo] = useState();
  const [email, setEmail] = useState();

  const [user_id, setUserId] = useState("");
  const [location_id, setLocationId] = useState("");
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(() => {
    getAllCategory();
    getAllCareer();
    getData();
  }, []);

  let { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const getData = () => {
    var abc = {
      dz_location_id: id,
    };
    showLoader();

    API_SERVICE.GetlLocationDetail(abc).then(({ data }) => {
      if (data) {
        hideLoader();
        setListOfItems(data?.data?.operation_hour);
        setEditData(data?.data);
        setUserId(data?.data?.dz_user_id);
        setLocationId(data?.data?.id);
        setDescription(data?.data?.location_title);
        setPhoneNo(data?.data?.location_phone_no);
        setEmail(data?.data?.business_email);
        setAddress(data?.data?.address);
        setLat(data?.data?.latitude);
        setLng(data?.data?.longitude);
        setCareerId(data?.data?.dz_career_id);
        setCategoryId(data?.data?.daizy_category_id);
      }
    });
  };

  const getAllCategory = () => {
    API_SERVICE.GetCategory().then(({ data }) => {
      if (data?.data) {
        setCategory(data.data);
      }
    });
  };
  const getAllCareer = () => {
    API_SERVICE.GetCareer().then(({ data }) => {
      if (data?.data) {
        setCareer(data.data);
      }
    });
  };

  const [lat, setLat] = useState(null);
  const [Address, setAddress] = useState();

  const [lng, setLng] = useState(null);

  const findFormErrors = () => {
    const {
      dz_career_id,
      daizy_category_id,
      additional_information,
      location_title,
      address,
    } = form;
    const newErrors = {};
    if (!dz_career_id || dz_career_id === "")
      newErrors.dz_career_id = "Select Career!";
    if (!daizy_category_id || daizy_category_id === "")
      newErrors.daizy_category_id = "Select Category!";
    if (!additional_information || additional_information === "")
      newErrors.additional_information = "Enter  Additional Information!";
    if (!location_title || location_title === "")
      newErrors.location_title = "Enter Location Title!";
    return newErrors;
  };

  const MyCheckBoxList = [
    {
      day: "Monday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Tuesday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Wednesday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Thursday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Friday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Saturday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: true,
    },
    {
      day: "Sunday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    form["latitude"] = lat;
    form["longitude"] = lng;
    const {
      dz_career_id,
      daizy_category_id,
      additional_information,
      location_title,
      address,
      category_description,
      business_email,
      location_phone_no,
    } = form;
    // setPhoneNo(location_phone_no)
    // setEmail(business_email)

    const newErrors = findFormErrors();
    const abc = {};
    abc["operation_hour"] = listOfItems;
    abc["daizy_category_id"] = CategoryId;
    abc["dz_career_id"] = dz_career_id ? dz_career_id : CreerId;
    abc["address"] = Address;
    abc["additional_information"] = form.additional_information
      ? form.additional_information
      : EditData?.additional_information;
    abc["location_title"] = form.location_title
      ? form.location_title
      : EditData?.location_title;
    abc["dz_user_id"] = user_id;
    abc["dz_location_id"] = location_id;

    if (lng) {
      abc["longtitude"] = lng;
      abc["lattitude"] = lat;
    }

    if (CategoryId == 5) {
      {
        // console.log(form.category_description)
        if (form.category_description == "") {
          toast.error("Enter Description");
          return false;
        }
      }
    } else {
      abc["category_description"] = category_description
        ? category_description
        : EditData?.category_description;
    }
    // || form.category_description == undefined
    if (CategoryId == 6) {
      {
        if (form.category_description == "") {
          toast.error("Enter Description");
          return false;
        }
      }
    } else {
      abc["category_description"] = category_description
        ? category_description
        : EditData?.category_description;
    }
    if (form.location_title == "General Manager") {
      {
        if (phonno == null) {
          // toast.error("Enter Phone Number");
        }
        if (email == "") {
          // toast.error("Enter Email");
        }
      }
    } else {
      abc["location_phone_no"] = form.location_phone_no
        ? form.location_phone_no
        : phonno;
      abc["business_email"] = form.business_email ? form.business_email : email;
    }
    if (form.location_title == "Manager" || phonno == null || email == null) {
      {
        if (phonno == "") {
          // toast.error("Enter Phone Number");
        }
        if (email == "") {
          // toast.error("Enter Email");
        }
      }
    } else {
      abc["location_phone_no"] = form.location_phone_no
        ? form.location_phone_no
        : phonno;
      abc["business_email"] = form.business_email ? form.business_email : email;
    }
    if (form.location_title == "Employee" || phonno == null || email == null) {
      {
        if (phonno == "") {
          // toast.error("Enter Phone Number");
        }
        if (email == "") {
          // toast.error("Enter Email");
        }
      }
    } else {
      abc["location_phone_no"] = form.location_phone_no
        ? form.location_phone_no
        : phonno;
      abc["business_email"] = form.business_email ? form.business_email : email;
    }

    if (des == "Owner") {
      {
        if (agree == false) {
          toast.error(
            "Please confirm the permission by selection the checkbox"
          );
        } else {
          const { business_email: _, ...newObj } = abc;
          delete newObj.location_phone_no;
          //  console.log(newObj)

          if (newObj.category_description == null) {
            delete newObj.category_description;
          }
          showLoader();

          API_SERVICE.EditLocation(newObj)
            .then(({ data }) => {
              hideLoader();

              if (data.status == true) {
                toast.success(data.message);
                navigate("/anchor-locations");
              } else {
                toast.error(data.message);
              }
            })
            .catch(() => {});
        }
      }
    } else {
      abc["location_phone_no"] = form.location_phone_no
        ? form.location_phone_no
        : phonno;
      abc["business_email"] = form.business_email ? form.business_email : email;

      if (abc.category_description == null) {
        delete abc.category_description;
      }
      showLoader();

      API_SERVICE.EditLocation(abc)
        .then(({ data }) => {
          hideLoader();

          if (data.status == true) {
            toast.success(data.message);
            navigate("/anchor-locations");
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          // toast.error(data.message);
        });
    }
  };

  const [checked, setChecked] = useState([]);
  const [array, setArray] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    const newFile = updatedList.map((file) => {
      return { day: file };
    });
    setArray(newFile);
    setChecked(updatedList);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.name + " " + place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
    }
  };

  const [listOfItems, setListOfItems] = useState([]);

  const updateListOfItems = (itemIndex, newsChecked) => {
    const updatedListOfItems = [...listOfItems];
    updatedListOfItems[itemIndex].status = newsChecked;
    // updatedListOfItems[itemIndex].start_time = newsChecked;
    setListOfItems(updatedListOfItems);
  };

  const updateListOfItems1 = (itemIndex, newsChecked) => {
    const updatedListOfItems = [...listOfItems];
    updatedListOfItems[itemIndex].start_time = newsChecked;
    setListOfItems(updatedListOfItems);
  };

  const updateListOfItems2 = (itemIndex, newsChecked1) => {
    const updatedListOfItems = [...listOfItems];
    updatedListOfItems[itemIndex].end_time = newsChecked1;
    setListOfItems(updatedListOfItems);
  };
  const [agree, setAgree] = useState(true);

  const myLibraries = ["places"];

  let libRef = React.useRef(myLibraries);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: parameters.GOOGLE_API_KEY,
    libraries: libRef.current,
  });
  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section loc-register-section">
          <Button className="back-btn d-block d-md-none" variant="link">
            <Link to="/anchor-locations">
              <img src={backBtn} alt="menu" />
            </Link>
          </Button>
          <Container>
            <div className="auth-block">
              <Row>
                <Col sm={6} className="left-block">
                  <div className="head text-center">
                    <h1>Location Edit</h1>
                    <div className="mobile-logo d-block d-md-none text-center">
                      <img src={artwork} alt="artwork" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col sm={6} className="left-block">
                  <Form>
                    <Form.Group className="form-group" controlId="dz_career_id">
                      <Form.Select
                        className="form-control"
                        aria-label="Select Career"
                        onChange={(e) =>
                          setField("dz_career_id", e.target.value)
                        }
                        isInvalid={!!errors.dz_career_id}
                      >
                        {career?.map((obj, index) => (
                          <>
                            {/* <select name="content" value={"Military Specific Occupations"} > */}
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                              key={index}
                            >
                              Location Type
                            </option>
                            <option
                              value={obj.id}
                              disabled={obj.id == 2}
                              selected={obj.id == CreerId}
                            >
                              {obj.name}
                            </option>
                            {/* </select> */}
                          </>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.dz_career_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="form-group"
                      controlId="daizy_category_id"
                    >
                      <Form.Select
                        className="form-control"
                        aria-label="Category"
                        // onChange={(e) => setCategoryId(e.target.value)}
                        isInvalid={!!errors.daizy_category_id}
                        onChange={(e) =>
                          setField(
                            "daizy_category_id",
                            e.target.value,
                            setCategoryId(e.target.value)
                          )
                        }
                      >
                        {category?.map((obj, index) => (
                          <>
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                              key={index}
                            >
                              Select Category
                            </option>
                            <option
                              value={obj.id}
                              selected={obj.id == CategoryId}
                            >
                              {obj.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.daizy_category_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {CategoryId == 5 ? (
                      <Form.Group
                        className="form-group"
                        controlId="locationTitle"
                      >
                        <Form.Control
                          defaultValue={EditData?.category_description}
                          type="text"
                          placeholder="Category Description"
                          onChange={(e) =>
                            setField("category_description", e.target.value)
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {CategoryId == 6 ? (
                      <Form.Group
                        className="form-group"
                        controlId="locationTitle"
                      >
                        <Form.Control
                          defaultValue={EditData?.category_description}
                          type="text"
                          placeholder="Category Description"
                          onChange={(e) =>
                            setField("category_description", e.target.value)
                          }
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group
                      className="form-group"
                      controlId="additionalInformation"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Additional Information"
                        onChange={(e) =>
                          setField("additional_information", e.target.value)
                        }
                        defaultValue={EditData?.additional_information}
                        isInvalid={!!errors.additional_information}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.additional_information}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      className="form-group"
                      controlId="locationTitle"
                    >
                      <Form.Select
                        className="form-control"
                        aria-label="Select Association to Location"
                        onChange={(e) =>
                          setField(
                            "location_title",
                            e.target.value,
                            setDescription(e.target.value)
                          )
                        }
                        isInvalid={!!errors.location_title}
                      >
                        <>
                          <option value="" disabled selected hidden>
                            Association to Location
                          </option>
                          <option
                            value="Owner"
                            selected={EditData?.location_title == "Owner"}
                          >
                            Owner
                          </option>
                          <option
                            value="General Manager"
                            selected={
                              EditData?.location_title == "General Manager"
                            }
                          >
                            General Manager
                          </option>
                          <option
                            value="Manager"
                            selected={EditData?.location_title == "Manager"}
                          >
                            Manager
                          </option>
                          <option
                            value="Employee"
                            selected={EditData?.location_title == "Employee"}
                          >
                            Employee
                          </option>
                        </>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.location_title}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {form.location_title == "Owner" || des == "Owner" ? (
                      <Form.Group
                        className="form-group"
                        controlId="additionalInformation"
                      >
                        <Form.Check
                          className="d-flex"
                          type="checkbox"
                          label="I have permission from the ownership of this establishment to register this location as an anchor point."
                          checked={agree}
                          onChange={() => setAgree((prev) => !prev)}
                        />
                      </Form.Group>
                    ) : des == "General Manager" ||
                      form.location_title == "General Manager" ||
                      des == "Manager" ||
                      form.location_title == "Manager" ||
                      des == "Employee" ||
                      form.location_title == "Employee" ? (
                      <>
                        <Form.Group
                          className="form-group"
                          controlId="location_phone_no"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Location Telephone Number"
                            defaultValue={EditData?.location_phone_no}
                            onChange={(e) =>
                              setField(
                                "location_phone_no",
                                e.target.value,
                                setPhoneNo(e.target.value)
                              )
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.location_phone_no}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="form-group"
                          controlId="business_email"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Business Email"
                            defaultValue={EditData?.business_email}
                            onChange={(e) =>
                              setField(
                                "business_email",
                                e.target.value,
                                setEmail(e.target.value)
                              )
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.business_email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {errors.location_phone_no}
                        </Form.Control.Feedback>
                      </>
                    ) : null}

                    <Form.Group
                      className="form-group mb-1 mb-md-0"
                      controlId="address"
                    >
                      {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                          onChange={(e) => setField("address", e.target.value)}
                        >
                          <input
                            defaultValue={Address}
                            type="text"
                            className="form-control"
                            placeholder="Select Address"
                          />
                        </StandaloneSearchBox>
                      ) : null}
                      {/* {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                          onChange={(e) => setField("address", e.target.value)}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Address"
                          />
                        </StandaloneSearchBox>
                      ) : null} */}
                      {/* <LoadScript
                        googleMapsApiKey={parameters.GOOGLE_API_KEY}
                        libraries={["places"]}
                      >
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                          onChange={(e) => setField("address", e.target.value)}
                        >
                          <input
                            defaultValue={Address}
                            type="text"
                            className="form-control"
                            placeholder="Select Address"
                          />
                        </StandaloneSearchBox>
                      </LoadScript> */}
                    </Form.Group>
                  </Form>
                </Col>
                <Col
                  sm={6}
                  className="d-flex flex-column justify-content-between"
                >
                  <div className="hours-list">
                    <p>
                      <b>Operation Hours</b>
                    </p>

                    {listOfItems.map((item, index) => (
                      <>
                        <Row className="item">
                          <Col xs={5}>
                            <Form.Check
                              key={index}
                              type="checkbox"
                              label={item.day}
                              defaultChecked={item.status}
                              onChange={() =>
                                updateListOfItems(index, !item.status)
                              }
                            />
                          </Col>
                          <Col
                            xs={7}
                            lg={6}
                            className="d-flex justify-content-evenly"
                          >
                            <Form.Select
                              className="form-control"
                              aria-label="Category"
                              //  onChange={(ev) =>
                              //    setState({ ...state, index: ev.target.value })
                              //  }
                              onChange={(ev) =>
                                updateListOfItems1(index, ev.target.value)
                              }
                              //  defaultValue={item.start_time}
                              defaultValue={item.start_time}
                            >
                              <option
                                value=""
                                selected="selected"
                                hidden="hidden"
                              >
                                0:00
                              </option>
                              <option value="24:00">12:00 am</option>
                              <option value="01:00">1:00 am</option>
                              <option value="02:00">02:00 am</option>
                              <option value="03:00">03:00 am</option>
                              <option value="04:00">04:00 am</option>
                              <option value="05:00">05:00 am</option>
                              <option value="06:00">06:00 am</option>
                              <option value="07:00">07:00 am</option>
                              <option value="08:00">08:00 am</option>
                              <option value="09:00">09:00 am</option>
                              <option value="10:00">10:00 am</option>
                              <option value="11:00">11:00 am</option>
                              <option value="12:00">12:00 pm</option>
                              <option value="13:00">1:00 pm</option>
                              <option value="14:00">2:00 pm</option>
                              <option value="15:00">3:00 pm</option>
                              <option value="16:00">4:00 pm</option>
                              <option value="17:00">5:00 pm</option>
                              <option value="18:00">6:00 pm</option>
                              <option value="19:00">7:00 pm</option>
                              <option value="20:00">8:00 pm</option>
                              <option value="21:00">9:00 pm</option>
                              <option value="22:00">10:00 pm</option>
                              <option value="23:00">11:00 pm</option>
                            </Form.Select>
                            <Form.Select
                              className="form-control"
                              aria-label="Category"
                              //  onChange={(ev) =>
                              //    setState({ ...state, EMon: ev.target.value })
                              //  }
                              onChange={(ev) =>
                                updateListOfItems2(index, ev.target.value)
                              }
                              defaultValue={item.end_time}
                            >
                              <option
                                value=""
                                selected="selected"
                                hidden="hidden"
                              >
                                0:00
                              </option>
                              <option value="24:00">12:00 am</option>
                              <option value="01:00">1:00 am</option>
                              <option value="02:00">02:00 am</option>
                              <option value="03:00">03:00 am</option>
                              <option value="04:00">04:00 am</option>
                              <option value="05:00">05:00 am</option>
                              <option value="06:00">06:00 am</option>
                              <option value="07:00">07:00 am</option>
                              <option value="08:00">08:00 am</option>
                              <option value="09:00">09:00 am</option>
                              <option value="10:00">10:00 am</option>
                              <option value="11:00">11:00 am</option>
                              <option value="12:00">12:00 pm</option>
                              <option value="13:00">1:00 pm</option>
                              <option value="14:00">2:00 pm</option>
                              <option value="15:00">3:00 pm</option>
                              <option value="16:00">4:00 pm</option>
                              <option value="17:00">5:00 pm</option>
                              <option value="18:00">6:00 pm</option>
                              <option value="19:00">7:00 pm</option>
                              <option value="20:00">8:00 pm</option>
                              <option value="21:00">9:00 pm</option>
                              <option value="22:00">10:00 pm</option>
                              <option value="23:00">11:00 pm</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </>
                    ))}

                    {/* </div>
                  <Button type="submit" className="theme-btn" onClick={handleSubmit}><span>Submit</span></Button>
                </Col> */}
                  </div>
                  <Button
                    type="submit"
                    className="theme-btn"
                    onClick={handleSubmit}
                  >
                    <span>Submit</span>
                  </Button>
                </Col>
              </Row>
            </div>
            {loader}
          </Container>
        </section>
      </main>
    </>
  );
}
export default LocationEdit;
