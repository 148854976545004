import React, { useEffect, useState,useContext }   from 'react'
import { Button, Container } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom'
import { AppContext } from "../../context";
import API_SERVICE from "../../services/api-service.js";

import logoBig from "./../../assets/images/logo-big.png"
import approvedIcon from "./../../assets/images/approved-icon.svg"
import useFullPageLoader from "../../hooks/FullPageLoader";

function RequestApproved() {
  const [isAnimation, setIsAnimation] = useState(false);
  useEffect(() => {
    setIsAnimation(true)
  }, []);
  const navigate = useNavigate();


  const navigateLocation = () => {
    navigate('/anchor-locations');
    localStorage.setItem('screen', JSON.stringify('true'))

  };
  const { users } = useContext(AppContext);
  
  useEffect(() => {
    // if(!users){
    //   navigate(`/`);
    // }else{
      GetScreen();
    // }
  }, []);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
 
  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    showLoader();
    
    API_SERVICE.GetScreen().then(({ data }) => {
      hideLoader();
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section request-approved">
          <Container>
            <div className="auth-block text-center d-flex d-md-block flex-column justify-content-between">
              {/* <h2 className="mb-0">Applied as Anchor</h2> */}
              <div className="center-content">
                <img src={logoBig} className="artwork" alt="logo" />
                {/* <img src={approvedIcon} className="approved-icon" alt="approved" /> */}
                <svg className={`approved-icon ${isAnimation && "active"}`} width="173" height="163" viewBox="0 0 173 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="85.8796" cy="93.6831" r="67" fill="#070302" class="svg-elem-1"></circle>
                  <circle cx="166.38" cy="33.1831" r="6.5" fill="#070302" class="svg-elem-2"></circle>
                  <ellipse cx="11.3796" cy="10.6831" rx="10.5" ry="10" fill="#070302" class="svg-elem-3"></ellipse>
                  <ellipse cx="5.87964" cy="137.183" rx="5" ry="5.5" fill="#070302" class="svg-elem-4"></ellipse>
                  <ellipse cx="154.38" cy="160.683" rx="2.5" ry="2" fill="#070302" class="svg-elem-5"></ellipse>
                  <path d="M80.9023 111.801C79.4813 111.801 78.0605 111.192 77.2486 109.974L65.8816 95.3594C64.2577 93.3296 64.6638 90.4879 66.6936 88.864C68.7234 87.2401 71.5651 87.6461 73.189 89.6759L80.9023 99.6221L97.9528 77.497C99.5767 75.4673 102.418 75.0612 104.448 76.6851C106.478 78.309 106.884 81.1507 105.26 83.1805L84.5559 109.974C83.541 110.989 82.3232 111.801 80.9023 111.801Z" fill="white" class="svg-elem-6"></path>
                  <circle cx="114.38" cy="104.183" r="2.5" fill="white" class="svg-elem-7"></circle>
                  <circle cx="48.8796" cy="76.6831" r="4" fill="white" class="svg-elem-8"></circle>
                  <circle cx="107.38" cy="55.1831" r="1.5" fill="white" class="svg-elem-9"></circle>
                  <circle cx="65.8796" cy="125.683" r="2" fill="white" class="svg-elem-10"></circle>
                  <circle cx="87.8796" cy="77.6831" r="1" fill="white" class="svg-elem-11"></circle>
                </svg>

                <h1 className="mb-0">Location Approved</h1>
              </div>
              <Button className="theme-btn" onClick={navigateLocation}><span>Continue</span></Button>
              {/* <p className="bottom-note text-center"><Link to="/anchor-locations"><b>Next</b></Link></p> */}

            </div>
    {loader}

          </Container>
        </section>
      </main>
    </>
  )
}
export default RequestApproved
