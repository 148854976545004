import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/global.scss';
import App from './App';
import { onMessageListener } from "./firebase";

const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log(typeof typescript !== 'undefined');


root.render(
  <React.StrictMode>
    <App />
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorkerRegistration.register();

