import React, {
  useEffect,
  useState,
  useRef,
  // useLoadScript,
  useContext,
} from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import artwork from "./../../assets/images/loc-registration-art.svg";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import API_SERVICE from "../../services/api-service.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../context";
import {
  StandaloneSearchBox,
  LoadScript,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import parameters from "../../parameters.ts";
// import { hasUserRole } from "../../services/Utility.ts";
import Autocomplete from "react-google-autocomplete";
import useFullPageLoader from "../../hooks/FullPageLoader";



function LocationRegistration() {
  const buttonRef = useRef(null);

  const navigate = useNavigate();
  const inputRef = useRef();
  const [lat, setLat] = useState(null);
  const [Address, setAddress] = useState(null);

  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(false);
  const [category, setCategory] = useState();
  const [career, setCareer] = useState();
  const [Api, setAPI] = useState();

  const [state, setState] = React.useState({});

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const { users } = useContext(AppContext);

  useEffect(() => {
    getAllCategory();
    getAllCareer();
  }, []);

  const getAllCategory = () => {
    API_SERVICE.GetCategory().then(({ data }) => {
      if (data?.data) {
        setCategory(data.data);
      }
    });
  };

  const getAllCareer = () => {
    API_SERVICE.GetCareer().then(({ data }) => {
      if (data?.data) {
        setCareer(data.data);
      }
    });
  };

  const findFormErrors = () => {
    const {
      dz_career_id,
      daizy_category_id,
      additional_information,
      location_title,
      category_description,
      location_phone_no,
      business_email,
      address,
    } = form;
    const newErrors = {};
    if (!dz_career_id || dz_career_id === "")
      newErrors.dz_career_id = "Select career";
    if (!daizy_category_id || daizy_category_id === "")
      newErrors.daizy_category_id = "Select category";
    if (!location_title || location_title === "")
      newErrors.location_title = "Select location title";

    return newErrors;
  };

  const MyCheckBoxList = [
    {
      day: "Monday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Tuesday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Wednesday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Thursday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Friday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Saturday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
    {
      day: "Sunday",
      start_time: "0:00",
      end_time: "0:00",
      is_working: false,
    },
  ];
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  
  const handleSubmit = (e) => {
    // console.log(status);
    e.preventDefault();
    form["latitude"] = lat;
    form["longitude"] = lng;
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      array.forEach(function (el) {
        if (el.day == "Monday") {
          el.start_time = state.SMon;
          el.end_time = state.EMon;
          el.is_working = true;
        }
        if (el.day == "Tuesday") {
          el.start_time = state.STue;
          el.end_time = state.ETue;
          el.is_working = true;
        }
        if (el.day == "Wednesday") {
          el.start_time = state.SWed;
          el.end_time = state.EWed;
          el.is_working = true;
        }
        if (el.day == "Thursday") {
          el.start_time = state.SThu;
          el.end_time = state.EThu;
          el.is_working = true;
        }
        if (el.day == "Friday") {
          el.start_time = state.SFri;
          el.end_time = state.EFri;
          el.is_working = true;
        }
        if (el.day == "Saturday") {
          el.start_time = state.SSat;
          el.end_time = state.ESat;
          el.is_working = true;
        }
        if (el.day == "Sunday") {
          el.start_time = state.SSun;
          el.end_time = state.ESun;
          el.is_working = true;
        }
      });
      const finalArray = array.filter(
        (element) => element.start_time && element.end_time !== undefined
      );
      var filtered = MyCheckBoxList.filter(
        ({ day }) => !finalArray.some((el) => el.day === day)
      );
      var result = finalArray.concat(filtered);

      const sorter = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      };

      result.sort(function sortByDay(a, b) {
        let day1 = a.day.toLowerCase();
        let day2 = b.day.toLowerCase();
        return sorter[day1] - sorter[day2];
      });

      form["operation_hour"] = result;
      form["address"] = Address;
      form["additional_information"] = form.additional_information;
      if (form.additional_information == null) {
        toast.error("Enter Additional Information");
        setStatus(false);
        return false;
      } else if (form.additional_information == "") {
        toast.error("Enter Additional Information");
        setStatus(false);
        return false;
      }
      if (finalArray.length == 0) {
        setStatus(false);
      } else {
        setStatus(true);
      }
      if (
        form.daizy_category_id == 5 ? form.category_description == null : null
      ) {
        toast.error("Enter category description");
        setStatus(false);
        return false;
      } else {
        form["category_description"] = form.category_description;
        setStatus(true);
      }

      if (
        form.daizy_category_id == 6 ? form.category_description == null : null
      ) {
        toast.error("Enter cetegory description");
        setStatus(false);
        return false;
      } else {
        form["category_description"] = form.category_description;
        setStatus(true);
      }
      if (form.location_title == "Manager") {
        if (form.location_phone_no == null && "") {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.location_phone_no == "") {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.business_email == null) {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else if (form.business_email == "") {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else {
          form["location_phone_no"] = form.location_phone_no;
          form["business_email"] = form.business_email;
          setStatus(true);
        }
      }
      if (form.location_title == "General Manager") {
        if (form.location_phone_no == null) {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.location_phone_no == "") {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.business_email == null) {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else if (form.business_email == "") {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else {
          form["location_phone_no"] = form.location_phone_no;
          form["business_email"] = form.business_email;
          setStatus(true);
        }
      }
      if (form.location_title == "Employee") {
        if (form.location_phone_no == null || agree == false) {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.location_phone_no == "") {
          toast.error("Enter phone number");
          setStatus(false);
          return false;
        } else if (form.business_email == null) {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else if (form.business_email == "") {
          toast.error("Enter business email");
          setStatus(false);
          return false;
        } else {
          form["location_phone_no"] = form.location_phone_no;
          form["business_email"] = form.business_email;
          setStatus(true);
        }
      }

      if (form.location_title == "Owner") {
        if (agree == false) {
          toast.error(
            "Please confirm the permission by selection the checkbox"
          );
          setStatus(false);

          return false;
        }
      }

      const isTrue = (value) => value.is_working === true;
      const Get = form.operation_hour.filter(isTrue);
      // console.log(form);

      if (Get.length !== 0) {
        // console.log(form);
        showLoader();

      
        API_SERVICE.AddLocation(form)
          .then(({ data }) => {
            hideLoader();
            if (data.status == true) {
              toast.success(data.message);
              GetScreen();
            } else {
              toast.error(data.message);
            }
          })
          .catch(() => {
            toast.success({ message: API_SERVICE.handleErrors(e) });
          });
      }
    }
  };

  const BackButton = JSON.parse(localStorage.getItem("userAuth"));

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    API_SERVICE.GetScreen().then(({ data }) => {
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  const [checked, setChecked] = useState([]);
  const [array, setArray] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    const newFile = updatedList.map((file) => {
      return { day: file };
    });
    setArray(newFile);
    setChecked(updatedList);
  };

  const myLibraries = ["places"];

  let libRef = React.useRef(myLibraries);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: parameters.GOOGLE_API_KEY,
    libraries: libRef.current,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.name + " " + place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
    }
  };

  const [agree, setAgree] = useState(false);

  const [num, setNum] = useState("");
  const limit = 15;
  const handleNumChange = (event) => {
    // console.log(event.target.value.slice(0, limit));
    setNum(event.target.value.slice(0, limit));
    setField("location_phone_no", event.target.value.slice(0, limit));
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section loc-register-section">
          <Button className="back-btn d-block d-md-none" variant="link">
            {BackButton.is_location_created == true ? (
              <Link to="/anchor-locations">
                <img src={backBtn} alt="menu" />
              </Link>
            ) : null}
            {/* <img src={backBtn} alt="menu" /> */}
          </Button>
          <Container>
            <div className="auth-block">
              <Row>
                <Col sm={6} className="left-block">
                  <div className="head text-center">
                    {BackButton.is_location_created == true ? (
                      <h1>Add New Location</h1>
                    ) : (
                      <h1>Location Registration</h1>
                    )}

                    <div className="mobile-logo d-block d-md-none text-center">
                      <img src={artwork} alt="artwork" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col sm={6} className="left-block">
                  <Form>
                    <Form.Group className="form-group" controlId="dz_career_id">
                      <Form.Select
                        className="form-control"
                        aria-label="Select Career"
                        onChange={(e) =>
                          setField("dz_career_id", e.target.value)
                        }
                        isInvalid={!!errors.dz_career_id}
                      >
                        {career?.map((obj, index) => (
                          <>
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                              key={index}
                            >
                              Location Type
                            </option>
                            <option value={obj.id} disabled={obj.id == 2}>
                              {obj.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.dz_career_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="form-group"
                      controlId="daizy_category_id"
                    >
                      <Form.Select
                        className="form-control"
                        aria-label="Category"
                        onChange={(e) =>
                          setField("daizy_category_id", e.target.value)
                        }
                        isInvalid={!!errors.daizy_category_id}
                      >
                        {category?.map((obj, index) => (
                          <>
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                              key={index}
                            >
                              Select Category
                            </option>
                            <option value={obj.id}>{obj.name}</option>
                          </>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.daizy_category_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="form-group"
                      controlId="daizy_category_id"
                    ></Form.Group>
                    {form.daizy_category_id == 6 ? (
                      <Form.Group
                        className="form-group"
                        controlId="locationTitle"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Category Description"
                          onChange={(e) =>
                            setField("category_description", e.target.value)
                          }
                          isInvalid={!!errors.category_description}
                        />
                      </Form.Group>
                    ) : null}
                    <Form.Control.Feedback type="invalid">
                      {errors.category_description}
                    </Form.Control.Feedback>
                    {form.daizy_category_id == 5 ? (
                      <Form.Group
                        className="form-group"
                        controlId="locationTitle"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Category Description"
                          onChange={(e) =>
                            setField("category_description", e.target.value)
                          }
                          isInvalid={!!errors.category_description}
                        />
                      </Form.Group>
                    ) : null}
                    <Form.Group
                      className="form-group"
                      controlId="additionalInformation"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Additional Information"
                        onChange={(e) =>
                          setField("additional_information", e.target.value)
                        }
                        // isInvalid={!!errors.additional_information}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                          {errors.additional_information}
                        </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group
                      className="form-group"
                      controlId="locationTitle"
                    >
                      <Form.Select
                        className="form-control"
                        aria-label="Select Association to Location"
                        onChange={(e) =>
                          setField("location_title", e.target.value)
                        }
                        isInvalid={!!errors.location_title}
                      >
                        <>
                          <option value="" disabled selected hidden>
                            Association to Location
                          </option>
                          <option value="Owner">Owner</option>
                          <option value="General Manager">
                            General Manager
                          </option>
                          <option value="Manager">Manager</option>
                          <option value="Employee">Employee</option>
                        </>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.location_title}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {form.location_title == "Owner" ? (
                      <Form.Group
                        className="form-group"
                        controlId="additionalInformation"
                      >
                        <Form.Check
                          className="d-flex"
                          // style={{"width":"13%"}}

                          type="checkbox"
                          label="I have permission from the ownership of this establishment to register this location as an anchor point."
                          checked={agree}
                          onChange={() => setAgree((prev) => !prev)}
                        />
                      </Form.Group>
                    ) : form.location_title == "General Manager" ||
                      form.location_title == "Manager" ||
                      form.location_title == "Employee" ? (
                      <>
                        <Form.Group
                          className="form-group"
                          controlId="location_phone_no"
                        >
                          {/* <Form.Control
                            type="number"
                            placeholder="Location Telephone Number"
                            onChange={(e) =>
                              setField("location_phone_no", e.target.value)
                            }
                          /> */}
                          <Form.Control
                            value={num}
                            type="number"
                            placeholder="Location Telephone Number"
                            // onChange={(e) => setField("amount", e.target.value)}
                            // isInvalid={!!errors.amount}
                            onChange={handleNumChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.location_phone_no}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="form-group"
                          controlId="business_email"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Business Email"
                            onChange={(e) =>
                              setField("business_email", e.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.business_email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {errors.location_phone_no}
                        </Form.Control.Feedback>
                      </>
                    ) : null}

                    <Form.Group
                      className="form-group mb-1 mb-md-0"
                      controlId="address"
                    >
                      {/* <Autocomplete
                    className="form-control"
          apiKey={parameters.GOOGLE_API_KEY}
  onPlaceSelected={(place) => console.log(place)}
/> */}
                      {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                          onChange={(e) => setField("address", e.target.value)}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Address"
                          />
                        </StandaloneSearchBox>
                      ) : null}
                    </Form.Group>
                  </Form>
                </Col>
                <Col
                  sm={6}
                  className="d-flex flex-column justify-content-between"
                >
                  <div className="hours-list">
                    <p>
                      <b>Operation Hours</b>
                    </p>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Monday"
                          value="Monday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, SMon: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>
                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, EMon: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Tuesday"
                          id="Tuesday"
                          value="Tuesday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, STue: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, ETue: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Wednesday"
                          id="Wednesday"
                          value="Wednesday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, SWed: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, EWed: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Thursday"
                          id="Thursday"
                          value="Thursday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, SThu: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, EThu: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Friday"
                          id="Friday"
                          value="Friday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, SFri: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, EFri: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Saturday"
                          id="Saturday"
                          value="Saturday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Saturday"
                          onChange={(ev) =>
                            setState({ ...state, SSat: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, ESat: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="item">
                      <Col xs={5}>
                        <Form.Check
                          type="checkbox"
                          label="Sunday"
                          id="Sunday"
                          value="Sunday"
                          onChange={handleCheck}
                        />
                      </Col>
                      <Col
                        xs={7}
                        lg={6}
                        className="d-flex justify-content-evenly"
                      >
                        <Form.Select
                          className="form-control"
                          aria-label="Sunday"
                          onChange={(ev) =>
                            setState({ ...state, SSun: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                        <Form.Select
                          className="form-control"
                          aria-label="Category"
                          onChange={(ev) =>
                            setState({ ...state, ESun: ev.target.value })
                          }
                        >
                          <option value="" selected="selected" hidden="hidden">
                            0:00
                          </option>

                          <option value="24:00">12:00 am</option>
                          <option value="01:00">1:00 am</option>
                          <option value="02:00">02:00 am</option>
                          <option value="03:00">03:00 am</option>
                          <option value="04:00">04:00 am</option>
                          <option value="05:00">05:00 am</option>
                          <option value="06:00">06:00 am</option>
                          <option value="07:00">07:00 am</option>
                          <option value="08:00">08:00 am</option>
                          <option value="09:00">09:00 am</option>
                          <option value="10:00">10:00 am</option>
                          <option value="11:00">11:00 am</option>
                          <option value="12:00">12:00 pm</option>
                          <option value="13:00">1:00 pm</option>
                          <option value="14:00">2:00 pm</option>
                          <option value="15:00">3:00 pm</option>
                          <option value="16:00">4:00 pm</option>
                          <option value="17:00">5:00 pm</option>
                          <option value="18:00">6:00 pm</option>
                          <option value="19:00">7:00 pm</option>
                          <option value="20:00">8:00 pm</option>
                          <option value="21:00">9:00 pm</option>
                          <option value="22:00">10:00 pm</option>
                          <option value="23:00">11:00 pm</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    {/* </div>
                    <Button type="submit" className="theme-btn" onClick={handleSubmit}><span>Submit</span></Button>
                  </Col> */}
                  </div>
                  <Button
                    type="submit"
                    className="theme-btn"
                    onClick={handleSubmit}
                  >
                    <span>Submit</span>
                  </Button>
                </Col>
              </Row>
            </div>
        {loader}

          </Container>
        </section>
      </main>
    </>
  );
}
export default LocationRegistration;
