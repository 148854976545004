import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import delivered from "./../../assets/images/icon/right-icon.svg";
import rightArrow from "./../../assets/images/icon/right-arrow.svg";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "../../services/api-service.js";
import { AppContext } from "../../context";

function DeliveryPolicy() {
  return (
    <>
      <MobileMenu />
      <main className="main-section">
        <section className="common-section auth-section anchor-locations">
          <div className="d-block d-md-none text-center">
            <img src={textLogo} alt="arrow" />
          </div>
          <Container>
            <div className="auth-block">
              <Row className="">
                <Col sm={5} className="left-block">
                  <div className="head d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Delivery Policy</h4>
                  </div>
                  <div className="location-list">
                    <div className="item mb-2">
                      <h5>777 High St NY 40001</h5>
                      <p>7:00 AM - 9:00 PM</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check type="switch" id="custom-switch" label="" />
                        <div className="action">
                          <Button className="small-btn xsmall-btn outline-btn ms-1">
                            Edit
                          </Button>
                          <Button className="small-btn xsmall-btn ms-1">
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="item mb-2">
                      <h5>311 High St NY 40002</h5>
                      <p>7:00 AM - 9:00 PM</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check type="switch" id="custom-switch" label="" />
                        <div className="action">
                          <Button className="small-btn xsmall-btn outline-btn ms-1">
                            Edit
                          </Button>
                          <Button className="small-btn xsmall-btn ms-1">
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={7} className="right-block">
                  <div className="head d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Delivery Report</h4>
                  </div>
                  <div className="report-list">
                    <div className="item mb-2">
                      <div className="head-bar d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <p className="ids">324jsabk324322</p>
                          <p>Customer: Alex D</p>
                        </div>
                        <p className="date-time">
                          08/27/2022 9:00pm via AppFront
                        </p>
                      </div>
                      <div className="pick-drop d-flex">
                        <div className="address pick">
                          <small>PICKUP</small>
                          <p>150 Harrison Ave, Kearny, NJ 07032</p>
                        </div>
                        <div className="address drop">
                          <small>DROP OFF</small>
                          <p>401 Kingsland Ave, Harrison, NJ 07029</p>
                        </div>
                      </div>
                      <div className="bottom-bar d-flex justify-content-between">
                        <p className="delivered d-flex align-items-center">
                          <img
                            src={delivered}
                            alt="delivered"
                            className="right-icon"
                          />{" "}
                          DELIVERED
                        </p>
                        <p>Pass Thru: $2.00</p>
                      </div>
                    </div>
                    <div className="item mb-2">
                      <div className="head-bar d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <p className="ids">324jsabk324322</p>
                          <p>Customer: Alex D</p>
                        </div>
                        <p className="date-time">
                          08/27/2022 9:00pm via AppFront
                        </p>
                      </div>
                      <div className="pick-drop d-flex">
                        <div className="address pick">
                          <small>PICKUP</small>
                          <p>150 Harrison Ave, Kearny, NJ 07032</p>
                        </div>
                        <div className="address drop">
                          <small>DROP OFF</small>
                          <p>401 Kingsland Ave, Harrison, NJ 07029</p>
                        </div>
                      </div>
                      <div className="bottom-bar d-flex justify-content-between">
                        <p className="delivered d-flex align-items-center">
                          <img
                            src={delivered}
                            alt="delivered"
                            className="right-icon"
                          />{" "}
                          DELIVERED
                        </p>
                        <p>Pass Thru: $2.00</p>
                      </div>
                    </div>
                    <div className="item mb-2">
                      <div className="head-bar d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <p className="ids">324jsabk324322</p>
                          <p>Customer: Alex D</p>
                        </div>
                        <p className="date-time">
                          08/27/2022 9:00pm via AppFront
                        </p>
                      </div>
                      <div className="pick-drop d-flex">
                        <div className="address pick">
                          <small>PICKUP</small>
                          <p>150 Harrison Ave, Kearny, NJ 07032</p>
                        </div>
                        <div className="address drop">
                          <small>DROP OFF</small>
                          <p>401 Kingsland Ave, Harrison, NJ 07029</p>
                        </div>
                      </div>
                      <div className="bottom-bar d-flex justify-content-between">
                        <p className="delivered d-flex align-items-center">
                          <img
                            src={delivered}
                            alt="delivered"
                            className="right-icon"
                          />{" "}
                          DELIVERED
                        </p>
                        <p>Pass Thru: $2.00</p>
                      </div>
                    </div>
                    <div className="item mb-2">
                      <div className="head-bar d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <p className="ids">324jsabk324322</p>
                          <p>Customer: Alex D</p>
                        </div>
                        <p className="date-time">
                          08/27/2022 9:00pm via AppFront
                        </p>
                      </div>
                      <div className="pick-drop d-flex">
                        <div className="address pick">
                          <small>PICKUP</small>
                          <p>150 Harrison Ave, Kearny, NJ 07032</p>
                        </div>
                        <div className="address drop">
                          <small>DROP OFF</small>
                          <p>401 Kingsland Ave, Harrison, NJ 07029</p>
                        </div>
                      </div>
                      <div className="bottom-bar d-flex justify-content-between">
                        <p className="delivered d-flex align-items-center">
                          <img
                            src={delivered}
                            alt="delivered"
                            className="right-icon"
                          />{" "}
                          DELIVERED
                        </p>
                        <p>Pass Thru: $2.00</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mobile-block d-md-none">
                <Button className="viewall-btn d-flex justify-content-between align-items-center">
                  <span>View All</span>
                  <img src={rightArrow} alt="arrow" />
                </Button>
                <h1 className="d-flex justify-content-between">
                  <span>Payable Amount</span>
                  <span>$15.00</span>
                </h1>
                <Button className="theme-btn">
                  <span>Payout Method</span>
                </Button>
              </div>
            </div>
          </Container>
        </section>
      </main>
      <SupportChat />
    </>
  );
}
export default DeliveryPolicy;
