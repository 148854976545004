import React, { useState, useContext, useEffect, useRef, Profile } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_SERVICE from "../../services/api-service.js";
import logoBig from "./../../assets/images/logo-big.png";
import { AppContext } from "../../context";
import showPwdImg from "../../assets/images/icon/eye-icon.svg";
import hidePwdImg from "../../assets/images/icon/eye-close-icon.svg";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import useFullPageLoader from "../../hooks/FullPageLoader";


function UserProfile() {
  const buttonRef = useRef(null);
  const [disable, setDisable] = React.useState(false);

  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");

  const [username, setUserName] = useState("");

  const [mobileno, setMobileNumber] = useState("");

  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const { dispatchUserEvent } = useContext(AppContext);
  const { users } = useContext(AppContext);
  useEffect(() => {
    GetData();
  }, []);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

      

  const GetData = () => {
    showLoader();

        
    API_SERVICE.GetProfile().then(({ data }) => {
      if (data) {
        hideLoader();
        setFirstName(data.data.first_name);
        setLastName(data.data.last_name);
        setUserName(data.data.username);
        setMobileNumber(data.data.mobile_no);
        setEmail(data.data.email);
      }
    });
  };
  const handleSubmit = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();
    var payLoad = {
      first_name: firstname,
      last_name: lastname,
      username: username,
      mobile_no: mobileno,
      email: email,
    };
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      buttonRef.current.disabled = false;

    } else {
    // console.log(payLoad)
    showLoader();
      API_SERVICE.EditProfile(payLoad)
      .then(({ data }) => {
        hideLoader();
        buttonRef.current.disabled = false;
        if (data.status == true) {
          toast.success(data.message);
          navigate('/anchor-locations')
        }else{
          toast.error(data.message);

        }
      })
      .catch(() => {
        hideLoader();

        toast.success({message: API_SERVICE.handleErrors(e) });

      });
    }
  };

  const findFormErrors = () => {
    const newErrors = {};
    if (!firstname || firstname === "")
      newErrors.first_name = "Enter  firstname";
    if (!lastname || lastname === "") newErrors.last_name = "Enter  lastname";

    if (!mobileno || mobileno === "")
      newErrors.mobile_no = "Enter mobile number ";
      if (mobileno.length > 20)
      newErrors.mobile_no = "Enter valid phone number";

    return newErrors;
  };


  const limit = 10;
  const handleNumChange = (event) => {
    // console.log(event.target.value.slice(0, limit));
    setMobileNumber( event.target.value.slice(0, limit));
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section create-account-section">
          <Button className="back-btn d-block d-md-none" variant="link">
            <Link to="/anchor-locations">
              {" "}
              <img src={backBtn} alt="menu" />
            </Link>
          </Button>

          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Profile</h1>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    isInvalid={!!errors.first_name}
                    placeholder="First Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="lastName">
                  <Form.Control
                   value={lastname}
                   onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    placeholder="Last Name"
                    isInvalid={!!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="username">
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    disabled
                    placeholder="Username"
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group" controlId="email">
                  <Form.Control
                    disabled
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="mobileNumber">
                  {/* <Form.Control
                    type="number"
                    value={mobileno}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Mobile Number"
                    isInvalid={!!errors.mobile_no}
                  /> */}
                   <Form.Control
                    value={mobileno}
                    type="number"
                    placeholder="Enter Amount"
                    // onChange={(e) => setField("amount", e.target.value)}
                    isInvalid={!!errors.mobile_no}
                    onChange={handleNumChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile_no}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" className="theme-btn" ref={buttonRef}>
                  <span>Submit</span>
                </Button>
              </Form>
            </div>
        {loader}

          </Container>
        </section>
      </main>
    </>
  );
}
export default UserProfile;
