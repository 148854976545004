import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";

import chatIcon from "./../../assets/images/icon/chat-icon.svg";
import receiveIcon from "./../../assets/images/icon/receive-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import sendIcon from "./../../assets/images/icon/send-icon.svg";
import textLogo from "./../../assets/images/logo-text.png";
import { db, auth } from "../../firebase.js";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, onValue, set, push } from "firebase/database";
import backBtn from "./../../assets/images/icon/back-menu.svg";
import MobileMenu from "../../components/mobileMenu";
import API_SERVICE from "../../services/api-service.js";
import useChatScroll from "../../hooks/useChatScroll";

function SupportChat({ scroll }) {
  const [msg, setMsg] = useState("");
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const [updatingData, setUpdatingData] = useState();

  const [count, setCount] = useState();
  const [oriData, setOriData] = useState();

  const [unread, setUnread] = useState(false);

  const [messages, setMessages] = useState([]);
  const user = localStorage.getItem("userAuth");
  const firebase_key = JSON.parse(user).firebase_key;

  useEffect(() => {
    const db = getDatabase();
    const getData = ref(db, `dz_messages/${firebase_key}`);
    onValue(getData, (snapshot) => {
      // console.log(snapshot.val())
      setData(snapshotToArray(snapshot));

      setOriData(snapshot.val());
      // console.log(snapshot.val());
      setUpdatingData(snapshotToArray1(snapshot));

      let lastElement = snapshotToArray1(snapshot)
        ? snapshotToArray1(snapshot)[snapshotToArray1(snapshot).length - 1]
        : null;
      setUnread(lastElement?.status == "unread" ? true : false);
    });

    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
    });
  }, []);

  function snapshotToArray(snapshot) {
    var returnArr = [];
    snapshot.forEach(function (childSnapshot) {
      var item = childSnapshot.val();
      item.key = childSnapshot.key;

      returnArr.push(item);
    });

    return returnArr;
  }
  function snapshotToArray1(snapshot) {
    var returnArr = [];
    snapshot.forEach(function (childSnapshot) {
      var item = childSnapshot.val();
      item.key = childSnapshot.key;

      returnArr.push(item);
    });

    return returnArr;
  }

  const user1 = localStorage.getItem("userAuth");
  const userId = JSON.parse(user1).id;

  function sendMessage(e) {
    e.preventDefault();
    const db = getDatabase();
    API_SERVICE.UpdateChat().then(({ data }) => {
      // console.log(data)
      if (data?.status == true) {
        push(ref(db, `dz_messages/${firebase_key}`), {
          message: msg,
          created_at: Date.now(),
          s_type: "c",
          status: "unread",
          type: "t",
        }).then(() => {
          setMsg("");
        });
        if (userData) {
          userData.has_message = "Yes";
          userData.unread_count = userData.unread_count + 1;
        }
        set(ref(db, `dz_users/${firebase_key}`), userData).then(() => {
          setMsg("");
        });
      }
    });
  }

  function bodyHide() {
    if (document.body.classList.contains("overflow-hidden")) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");

      if (unread) {
        function* iter(obj) {
          for (let [key, value] of Object.entries(obj)) {
            if (Object(value) !== value) yield [obj, key, value];
            else yield* iter(value);
          }
        }
        for (let [obj, key, value] of iter(oriData)) {
          if (typeof value === "string") obj["status"] = "read";
        }
        const db = getDatabase();
        set(ref(db, `dz_messages/${firebase_key}`), oriData).then(() => {});
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (msg == "") {
      } else {
        sendMessage(event);
      }
    }
  };

  const ref1 = useChatScroll(data);

  return (
    <div className="support-chat">
      {/* <MobileMenu style={{'display':"none"}}/> */}
      <Dropdown onToggle={() => bodyHide()}>
        <Dropdown.Toggle
          variant="link"
          drop={"up"}
          id="dropdown-autoclose-true"
        >
          <img src={unread ? receiveIcon : chatIcon} alt="chat" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="chat-section ">
            <div className="head-block d-flex justify-content-between align-items-center">
              <img src={textLogo} alt="chat" />
              <p className="mb-0">Live Support</p>
            </div>
            <div className="chat-block d-flex flex-column" ref={ref1}>
              {data?.map(({ message, s_type, key, status, type }) => (
                <>
                  <div className={`${s_type == "a" ? "chat opp" : "chat"}`}>
                    {message}
                  </div>
                </>
              ))}
            </div>
            <div className="bottom-block">
              <Form.Control
                type="text"
                placeholder="Write your meassage here"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                variant="link"
                onClick={sendMessage}
                disabled={msg == "" ? true : false}
              >
                <img src={sendIcon} alt="send" />
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
export default SupportChat;
