import React,{useEffect,useContext}from 'react'
import { Container } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom'
import { AppContext } from "../../context";
import API_SERVICE from "../../services/api-service.js";
import artwork from "./../../assets/images/request-artwork.svg"
import useFullPageLoader from "../../hooks/FullPageLoader";

function RequestSending() {
  const navigate = useNavigate();
  const { users } = useContext(AppContext);
  
  useEffect(() => {
    // if(users){
      GetScreen()
  //   }
  //   else{
  //     navigate('/');
  // }
  }, []);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
 
  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    showLoader();
   
    API_SERVICE.GetScreen().then(({ data }) => {
      hideLoader();
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section request-sending">
          <Container>
            <div className="auth-block text-center d-flex d-md-block flex-column justify-content-between">
              <h1 className="title d-none d-md-block">Location Registration</h1>
              <h2>Applied as Anchor</h2>
              <img src={artwork} className="artwork" alt="logo" />
              <h1>Request Sending...</h1>
              {/* <p className="bottom-note text-center"><Link to="/request-approved"><b>Next</b></Link></p> */}

            </div>
      {loader}

          </Container>
        </section>
      </main>
    </>
  )
}
export default RequestSending
