import React, { useState, useEffect } from "react";
import { getToken } from "../../firebase"
import firebase from 'firebase/compat/app';
import { getMessaging, onMessage } from "firebase/messaging";


const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  // console.log("Token found", isTokenFound);

  // const firebaseApp = firebase.initializeApp({
  //   apiKey: "AIzaSyBbesYYaZnaWRwEraiibsXFe02AfP_aOqQ",
  //   authDomain: "flowerpowernyc-243b2.firebaseapp.com",
  //   projectId: "flowerpowernyc-243b2",
  //   storageBucket: "flowerpowernyc-243b2.appspot.com",
  //   messagingSenderId: "2239216868",
  //   appId: "1:2239216868:web:06820d49654c88e16d00f1",
  //   measurementId: "G-57SGLH7ES4",
  //   databaseURL: "https://flowerpowernyc-243b2-default-rtdb.firebaseio.com/"
  // })
   // const firebaseApp = firebase.initializeApp({
  //   apiKey: "AIzaSyBbesYYaZnaWRwEraiibsXFe02AfP_aOqQ",
  //   authDomain: "flowerpowernyc-243b2.firebaseapp.com",
  //   projectId: "flowerpowernyc-243b2",
  //   storageBucket: "flowerpowernyc-243b2.appspot.com",
  //   messagingSenderId: "2239216868",
  //   appId: "1:2239216868:web:06820d49654c88e16d00f1",
  //   measurementId: "G-57SGLH7ES4",
  //   databaseURL: "https://flowerpowernyc-243b2-default-rtdb.firebaseio.com/"
  // })

  // useEffect(() => {
  //   let data;

  //   async function tokenFunc() {
  //     data = await getToken(setTokenFound);
  //     if (data) {
  //       // console.log("Token is", data);
  //     }
  //     return data;
  //   }

  //   tokenFunc();
  // }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;