import React from 'react'
import { Button, Container, Form } from 'react-bootstrap';

import logoBig from "./../../assets/images/logo-big.png"

function Otp() {
  return (
    <>
      <main className="main-section">
        <section className="common-section auth-section login-section">
          <Container>
            <div className="mobile-logo d-block d-md-none text-center">
              <img src={logoBig} alt="logo" />
            </div>
            <div className="auth-block">
              <div className="head text-center">
                <h1>Hello </h1>
                <h3>Verify your OTP</h3>
              </div>
              <Form>
                <Form.Group className="form-group" controlId="username">
                  <Form.Control type="text" className="text-center" placeholder="Verify OTP" />
                </Form.Group>
                <Button type="submit" className="theme-btn"><span>Submit</span></Button>
              </Form>
            </div>
          </Container>
        </section>
      </main>
    </>
  )
}
export default Otp
