import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Row,
  Badge,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import API_SERVICE from "../../services/api-service.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useGeolocated } from "react-geolocated";
import moment from "moment";
import DateFormat from "./../../services/Utility.ts";
import "./hello.css";
import firebase from "firebase/compat/app";
import { ref as sRef } from "firebase/storage";
import "firebase/database";
import {
  getDatabase,
  ref,
  onValue,
  push,
  update,
  set,
} from "firebase/database";
import useFullPageLoader from "../../hooks/FullPageLoader";

function AnchorLocationsPayment() {
  const [data, setData] = useState();
  const [status43, setStatus43] = useState(false);

  useEffect(() => {
    GetScreen();
    getUserData();
  }, [status43]);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      },
      watchPosition: true,
      geolocationProvider: navigator.geolocation,
    });

  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const GetScreen = () => {
    const screen = localStorage.getItem("screen");
    showLoader();

    API_SERVICE.GetScreen().then(({ data }) => {
      hideLoader();
      if (data.data.is_busin_profi_created == true) {
        if (
          data.data.is_busin_profi_created == true &&
          data.data.is_busin_profi_appr == true
        ) {
          if (data.data.is_location_created == true) {
            if (data.data.is_location_approved == true) {
              if (screen) {
                navigate("/anchor-locations");
              } else {
                navigate("/request-approved");
              }
            } else {
              navigate("/request-sending");
            }
          } else {
            navigate("/location-registration");
          }
        } else {
          navigate("/request-sent");
        }
      } else if (data.data.is_busin_profi_created == false) {
        navigate("/location-list");
      }
    });
  };

  const getUserData = () => {
    showLoader();

    API_SERVICE.UserLocationDetails().then(({ data }) => {
      hideLoader();
      if (data?.data) {
        setData(data.data);
      }
    });
  };
  const buildPayload = (formData) => {
    const payload = {
      dz_user_id: formData.dz_user_id,
      dz_location_id: formData.id,
      status: formData.status,
    };
    return payload;
  };
  const buildPayload1 = (formData) => {
    const payload1 = {
      dz_user_id: formData.dz_user_id,
      dz_location_id: formData.id,
      status:
        formData.status == true
          ? false
          : formData.status == false
          ? true
          : false,
    };
    return payload1;
  };

  // console.log(status43)

  const onChangeStatus = (event, item) => {
    item.status = event.target.checked;
    const payload = buildPayload(item);
    console.log(payload);
    const payload1 = buildPayload1(item);
    console.log(payload1);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="auth-block custom-popup justify-content-center ">
            <h1>Are you sure?</h1>
            <p>
              You want to {item.status == true ? "enable" : "disable"} this
              location?
            </p>
            <button
              className="small-btn xsmall-btn ms-1"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
            <button
              className="small-btn xsmall-btn ms-1"
              onClick={() => {
                API_SERVICE.LocationStatusChange(payload).then(({ data }) => {
                  if (data.status == true) {
                    onClose();
                    getUserData();
                    toast.success(data.message);
                  }
                });
              }}
            >
              Yes, {item.status == true ? "enabled" : "disabled"} it!
            </button>
          </div>
        );
      },
    });
    // API_SERVICE.LocationStatusChange(payload).then(({ data }) => {
    //   if (data.status == true) {
    //     toast.success(data.message);
    //   }
    // });
  };

  const buildPayloadDelete = (formData) => {
    const payload = {
      dz_user_id: formData.dz_user_id,
      dz_location_id: formData.id,
    };
    return payload;
  };

  const onDelete = (event, item) => {
    item.status = event.target.checked;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="auth-block custom-popup justify-content-center ">
            <h1>Are you sure?</h1>
            <p>You want to delete this location?</p>
            <button className="small-btn xsmall-btn ms-1" onClick={onClose}>
              No
            </button>
            <button
              className="small-btn xsmall-btn ms-1"
              onClick={() => {
                item.status = event.target.checked;
                const payload = buildPayloadDelete(item);
                showLoader();
                API_SERVICE.LocationDelete(payload).then(({ data }) => {
                  // console.log(data)
                  hideLoader();
                  if (data.status == true) {
                    toast.success(data.message);
                    onClose();
                    getUserData();
                  }
                });
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const navigateLocation = () => {
    navigate(`/location-registration-add`);
  };

  const navigateEdit = (event, item) => {
    navigate(`/location-registration/edit/${item.id}`);
  };

  const navigateLocationList = (event, item) => {
    navigate(`/location-list/${item.id}`);
  };

  const user = localStorage.getItem("userAuth");
  const firebase_key = JSON.parse(user).firebase_key;
  const userId = JSON.parse(user).id;
  const [orderData, setOrderData] = useState();
  const [userData, setUserData] = useState();
  const [orderDetail, setDetails] = useState();
  const [results, setResults] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersFinal, setFinalOrders] = useState([]);

  const [categories, setCategories] = useState([]);

  const [orderkey, setOrderKey] = useState([]);
  const [passthru, setOrderPassthruKey] = useState([]);
  const [oriData, setOriData] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
      var array = snapshot.val()?.order_key.split(",");
      var array1 = snapshot.val()?.order_passthrough_key?.split(",");
      setOrderKey(array);
      setOrderPassthruKey(array1);
      const orders = [];
      const categories = [];
      if (array) {
        array.forEach((item) => {
          const temp = item;
          const getData = ref(db, `dz_orders/${item}`);
          onValue(getData, (snapshot1) => {
            setOrderData(snapshot1.val());
            orders.push(snapshot1.val());
            setOrders(orders);

            const getData = ref(db, `dz_orders`);
            onValue(getData, (snapshot) => {
              setOriData(snapshotToArray(snapshot));
            });
            // console.log(orders);

            // const uniques1 = Object.values(
            //   orders.reduce((a, c) => {
            //     a[c?.created_at + "|" + c?.order_id] = c;
            //     return a;
            //   }, {})
            // );

            // console.log(uniques1);
            // const resultsFinal = uniques1.filter((element) => {
            //   return element !== null;
            // });

            // setFinalOrders(resultsFinal);

            array1.forEach((item1) => {
              const getData2 = ref(
                db,
                `dz_orders/${temp}/order_passthrough/${item1}`
              );
              onValue(getData2, (snapshot2) => {
                categories.push(snapshot2.val());
                setDetails(snapshot2.val());
              });
              const uniques = Object.values(
                categories.reduce((a, c) => {
                  a[
                    c?.pickup_anchor_location_id +
                      "|" +
                      c?.drop_off_anchor_location_id
                  ] = c;
                  return a;
                }, {})
              );

              const results = uniques.filter((element) => {
                return element !== null;
              });
              setResults(results);
            });
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const getData1 = ref(db, `dz_users/${firebase_key}`);
    onValue(getData1, (snapshot) => {
      setUserData(snapshot.val());
      var array = snapshot.val()?.order_key.split(",");
      var array1 = snapshot.val()?.order_passthrough_key?.split(",");
      setOrderKey(array);
      setOrderPassthruKey(array1);
      array1.forEach((item1) => {
        array.forEach((item) => {
          const orders111 = [];
          const mergeds = [];
          const getData = ref(db, `dz_orders`);
          onValue(getData, (snapshot) => {
            setOriData(snapshotToArray(snapshot));
          });
        });
      });
    });
  }, []);

  // console.log(oriData);

  function snapshotToArray(snapshot) {
    var returnArr = [];
    snapshot.forEach(function (childSnapshot) {
      var item = childSnapshot.val();
      item.key = childSnapshot.key;

      returnArr.push(item);
    });

    return returnArr;
  }

  const [check, setchecked] = useState(false);
  const [count, setCounting] = useState();
  const [passthruData, setPassthruData] = useState("");

  const [indexCheck, setIndexChecked] = useState([]);
  const [OTP, setOTP] = useState("");

  const handleCheck = (event, data, index) => {
    const db = getDatabase();
    const countings = ref(db, `dz_orders/${orderkey[index]}`);

    setIndexChecked([...indexCheck, data]);
    setchecked(!check);
    const getData1 = ref(
      db,
      `dz_orders/${orderkey[index]}/order_passthrough/${passthru[index]}`
    );
    onValue(getData1, (snapshot) => {
      setPassthruData(snapshot.val());
    });
    const getData = ref(db, `dz_orders/${orderkey[index]}/notification`);

    confirmAlert({
      onClickOutside: () => {
        setchecked(false, setIndexChecked([]));
      },
      customUI: ({ onClose }) => {
        return (
          <div className="auth-block custom-popup justify-content-center">
            <h1>Are you sure?</h1>
            {/* <OTPInput value={OTP} onChange={setOTP}  OTPLength={4} otpType="number"/> */}

            <Form.Control
              style={{ width: "50%", display: "inline" }}
              type="number"
              placeholder="Pin"
              id="pin"
              onChange={(e) =>
                (document.getElementById("pin").value = e.target.value.slice(
                  0,
                  4
                ))
              }
            />
            <p>
              {event.target.value == "pu"
                ? "Have you picked up your parcel"
                : event.target.value == "d"
                ? "Have you delivered your parcel"
                : "Thank You"}{" "}
              ?
            </p>
            <button
              className="small-btn xsmall-btn ms-1"
              onClick={() => {
                setchecked(false, onClose(), setIndexChecked([]));
              }}
            >
              No
            </button>
            <button
              className="small-btn xsmall-btn ms-1"
              onClick={() => {
                if (
                  orders[index].pin_no == document.getElementById("pin").value
                ) {
                  // onClose()
                  setchecked(!check);
                  var pick = {
                    status: event.target.value,
                    pickup_time: Date.now(),
                  };
                  var drop = {
                    status: event.target.value,
                    drop_off_time: Date.now(),
                  };
                  update(
                    getData1,
                    event.target.value == "pu"
                      ? pick
                      : (event.target.value = "d"
                          ? drop
                          : {
                              status: "p",
                              pickup_time: Date.now(),
                              drop_off_time: Date.now(),
                            })
                  ).then(
                    () => onClose(),
                    toast.success(
                      event.target.value == "pu"
                        ? "Picked up Successfully"
                        : "Delivered Successfully"
                    ),
                    setchecked(false),
                    setIndexChecked([])
                  );

                  // push new Node in Notification

                  var pickedUpNotifi = {
                    created_at: Date.now(),
                    description: `Order with #${orders[index].order_id} and Passthrough with #${results[index].ticket_id} has been picked up.`,
                    location_id: data.pickup_anchor_location_id,
                    notification_from: "Web",
                    notification_type: "Picked Up Success",
                    title: "Passthrough Picked Up",
                    order_no: `${orders[index].order_id}`,
                    user_id: `${userId}`,
                  };
                  var dropOffNotifi = {
                    created_at: Date.now(),
                    description: `Order with #${orders[index].order_id} and Passthrough with #${results[index].ticket_id} has been delivered.`,
                    location_id: data.pickup_anchor_location_id,
                    notification_from: "Web",
                    notification_type: "Delivered Success",
                    title: "Passthrough Delivered",
                    order_no: `${orders[index].order_id}`,
                    user_id: `${userId}`,
                  };
                  push(
                    getData,
                    event.target.value == "pu"
                      ? pickedUpNotifi
                      : // : event.target.value == "d"
                        // ? dropOffNotifi
                        dropOffNotifi
                  ).then(
                    () => onClose(),
                    setchecked(false),
                    setIndexChecked([])
                  );
                  const getOrderNotify = ref(
                    db,
                    `dz_orders/${orderkey[index]}`
                  );
                  update(getOrderNotify, {
                    notification_unread_count:
                      orders[index].notification_unread_count + 1,
                  }).then(
                    () => onClose(),
                    setchecked(false),
                    setIndexChecked([])
                  );
                } else {
                  if (document.getElementById("pin").value.length > 4) {
                    toast.error("Enter Only 4 Digit PIN");
                  } else {
                    toast.error("Enter Valid PIN");
                  }
                }
              }}
            >
              Yes, &nbsp;
              {event.target.value == "pu"
                ? "Picked Up"
                : event.target.value == "d"
                ? "Delivered"
                : "Yes"}
            </button>
          </div>
        );
      },
    });
  };

  const ViewAll = (event: any, item: any) => {
    navigate(`/view-orders/${item.id}`);
  };

  const WithDrawalRedirect = (event: any, item: any) => {
    navigate(`/withdrawals`);
  };

  // const arrayUniqueByKey = [...new Map(array.map(item =>
  //   [item[ticket_id], item])).values()];

  return (
    <>
      <MobileMenu />

      <main className="main-section">
        <section className="common-section auth-section anchor-locations anchor-locations-payment">
          <div className="d-block d-md-none text-center">
            <img src={textLogo} alt="arrow" />
          </div>
          <Container>
            <div className="auth-block">
              <div className="head d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Anchor Locations </h4>

                <Button className="small-btn" onClick={navigateLocation}>
                  Add New
                </Button>
              </div>

              {data?.location_detail.map((obj, index) => (
                <Accordion defaultActiveKey={0} key={index}>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{obj.address}</Accordion.Header>
                    <Accordion.Body>
                      <Row className="">
                        <Col sm={5} className="left-block">
                          <div className="location-list">
                            <div className="item mb-2">
                              <p className="loc-info">Location Info</p>
                              <h5>{obj.address}</h5>
                              <p>
                                {" "}
                                {
                                  obj.today_hour !== null
                                    ? // obj.today_hour.start_time !== "00:00" &&
                                      // obj.today_hour.end_time !== "00:00"
                                      // ?
                                      "Today's working hours :" +
                                      " " +
                                      obj.today_hour.start_time +
                                      " " +
                                      "to" +
                                      " " +
                                      obj.today_hour.end_time
                                    : "You are not working today."
                                  // : null
                                }
                              </p>
                              <p>
                                {obj.status == "pending" ? (
                                  <Badge Pill bg="warning" text="dark">
                                    Pending
                                  </Badge>
                                ) : null}
                                {obj.is_busin_profi_created == false ? (
                                  <Button
                                    className="small-btn xsmall-btn ms-1"
                                    onClick={(event) =>
                                      navigateLocationList(event, obj)
                                    }
                                  >
                                    Verify Business Profile
                                  </Button>
                                ) : null}
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                  checked={
                                    // status43 == false ? obj.is_working == "active" ? true : false  : true
                                    obj.is_working == "active" ? true : false
                                  }
                                  onChange={(event) =>
                                    onChangeStatus(event, obj)
                                  }
                                />
                                <div className="action">
                                  <Button
                                    className="small-btn xsmall-btn outline-btn ms-1"
                                    onClick={(event) =>
                                      navigateEdit(event, obj)
                                    }
                                  >
                                    Edit
                                  </Button>

                                  <Button
                                    className="small-btn xsmall-btn ms-1"
                                    onClick={(event) => onDelete(event, obj)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col sm={7} className="right-block">
                          <div className="head d-flex justify-content-between align-items-center">
                            <h4 className="mb-0">Delivery Report</h4>
                            <Button
                              className="small-btn xsmall-btn ms-1 "
                              onClick={(event) => ViewAll(event, obj)}
                            >
                              View All
                            </Button>
                          </div>

                          {orderkey.map((orderKey, index1) => (
                            <>
                              {oriData.map((Objcetos, index) =>
                                orderKey == Objcetos.key ? (
                                  <>
                                    {results.map((object1, index) =>
                                      // {[...new Map(results.map(item =>
                                      //   [item["ticket_id"], item])).values()].map(object5 => (
                                      //         <p>
                                      //           {object5.ticket_d}
                                      //           </p>
                                      //   ))}

                                      object1?.pickup_anchor_location_id ==
                                      obj.id ? (
                                        <>
                                          <div
                                            className="report-list"
                                            key={object1.ticket_id}
                                          >
                                            <div className="item mb-2">
                                              <div className="head-bar1 d-flex justify-content-between">
                                                <div className="d-flex justify-content-between">
                                                  <p className="ids">
                                                    #{object1.ticket_id}
                                                  </p>
                                                  <p>
                                                    Customer:{" "}
                                                    {
                                                      // orders.customer_name !== null
                                                      //   ? orders.customer_name
                                                      //   : orders[0].customer_name
                                                      Objcetos?.customer_name
                                                      // .substring(
                                                      //   0,
                                                      //   10
                                                      // )
                                                    }
                                                  </p>
                                                </div>
                                                <p className="date-time">
                                                  {/* {formatDate(object1.updated_at)} &nbsp; */}
                                                  {/* {moment(object1.updated_at).format("MM/DD/YYYY hh:mm A")} */}
                                                  PIN:
                                                  {Objcetos?.pin_no}
                                                </p>
                                                <p className="date-time">
                                                  {/* {formatDate(object1.updated_at)} &nbsp; */}
                                                  {/* {moment(object1.updated_at).format("MM/DD/YYYY hh:mm A")} */}
                                                  {DateFormat(
                                                    object1.updated_at
                                                  )}
                                                </p>
                                              </div>
                                              <div className="pick-drop d-flex">
                                                <div className="address pick">
                                                  <small>PICKUP</small>
                                                  <p>
                                                    {object1.pickup_location}{" "}
                                                  </p>
                                                  <p>
                                                    Name:{" "}
                                                    {object1.pickup_anchor_drop_off_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      object1.pickup_anchor_drop_off_name.slice(
                                                        1
                                                      )}{" "}
                                                  </p>
                                                </div>
                                                <div className="address drop">
                                                  <small>DROP OFF</small>
                                                  <p>
                                                    {object1.drop_off_location}
                                                  </p>
                                                  <p>
                                                    Name:{" "}
                                                    {object1.drop_off_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      object1.drop_off_name.slice(
                                                        1
                                                      )}
                                                  </p>
                                                </div>
                                              </div>

                                              {Objcetos?.note !== "" && (
                                                <div className="d-flex justify-content-between">
                                                  <p
                                                    className="ids"
                                                    style={{
                                                      fontSize: "11px",
                                                      marginLeft: "1%",
                                                    }}
                                                  >
                                                    Notes : {Objcetos?.note}
                                                  </p>
                                                </div>
                                              )}

                                              <div className="bottom-bar d-flex justify-content-between">
                                                {object1?.status == "d" ? (
                                                  <>
                                                    <p
                                                      className="delivered d-flex align-items-center"
                                                      style={{
                                                        fontSize: "15px",
                                                        color: "#008000",
                                                      }}
                                                    >
                                                      Delivered
                                                    </p>
                                                  </>
                                                ) : (
                                                  <p className="delivered d-flex align-items-center">
                                                    <Form.Check
                                                      style={{
                                                        fontSize:
                                                          "13px!important",
                                                      }}
                                                      type="checkbox"
                                                      className="smeet"
                                                      label={
                                                        object1?.status == "p"
                                                          ? "Mark as pick up"
                                                          : object1?.status ==
                                                            "pu"
                                                          ? "Mark as delivered"
                                                          : object1?.status ==
                                                            "d"
                                                          ? "Delivered"
                                                          : null
                                                      }
                                                      value={
                                                        object1?.status == "p"
                                                          ? "pu"
                                                          : object1?.status ==
                                                            "pu"
                                                          ? "d"
                                                          : object1?.status ==
                                                            "d"
                                                          ? "c"
                                                          : null
                                                      }
                                                      disabled={
                                                        object1?.status == "d"
                                                      }
                                                      onClick={(event) =>
                                                        handleCheck(
                                                          event,
                                                          object1,
                                                          index
                                                        )
                                                      }
                                                      checked={indexCheck.find(
                                                        (id) =>
                                                          id.ticket_id ==
                                                          object1.ticket_id
                                                      )}
                                                    />
                                                  </p>
                                                )}

                                                <p>
                                                  Passthrough: $
                                                  {object1.passthrogh_amount}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : null
                                    )}
                                    {/* </>
                                  ))} */}
                                  </>
                                ) : null
                              )}
                            </>
                          ))}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}

              <div className="mobile-block d-md-none">
                <h1 className="d-flex justify-content-between">
                  <span>Payable Amount</span>
                  <span>
                    ${userData?.current_balance ? userData?.current_balance : 0}
                  </span>
                </h1>

                <Button className="theme-btn" onClick={WithDrawalRedirect}>
                  <span>Payout Method</span>
                </Button>
              </div>
            </div>
            {loader}
          </Container>
        </section>
      </main>
      <SupportChat />
    </>
  );
}
export default AnchorLocationsPayment;
