import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import delivered from "./../../assets/images/icon/right-icon.svg";
import rightArrow from "./../../assets/images/icon/right-arrow.svg";
import SupportChat from "../../components/supportChat";
import MobileMenu from "../../components/mobileMenu";
import textLogo from "./../../assets/images/logo-text.png";
import API_SERVICE from "../../services/api-service.js";
import { AppContext } from "../../context";
import Faq from "react-faq-component";
import "./Faq.css";
import useFullPageLoader from "../../hooks/FullPageLoader";



function Faq1() {
  const [faq, setFaq] = useState([]);

  const newArrayOfObj = faq.map(
    ({ question: title, answer: content, ...rest }) => ({
      title,
      content,
      ...rest,
    })
  );

  const Card = (rank, suit) => {
    return { title: rank, rows: suit };
  };

  const cardOne = Card("FAQs", newArrayOfObj);

  useEffect(() => {
    getFaq();
  }, []);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  
  const getFaq = () => {
    showLoader();

    
    API_SERVICE.Faq().then(({ data }) => {
      if (data) {
        hideLoader();
        setFaq(data.data);
      }
    });
  };

  return (
    <>
      <MobileMenu />
      <main className="main-section">
        <section className="common-section auth-section anchor-locations faq-section">
          <Container>
            <div className="auth-block">
              <Row>
                <Col lg={11}>
                  <Faq
                    data={cardOne}
                    styles={{
                      bgColor: "white",
                      titleTextColor: "#48482a",
                      rowTitleColor: "#78789a",
                      rowTitleTextSize: "large",
                      rowContentColor: "#48484a",
                      rowContentTextSize: "18px",
                      rowContentPaddingTop: "10px",
                      rowContentPaddingBottom: "10px",
                      //   rowContentPaddingLeft: "50px",
                      rowContentPaddingRight: "24px",
                      arrowColor: "red",
                    }}
                    config={{
                      animate: true,
                    }}
                  />
                </Col>
              </Row>
            </div>
        {loader}

          </Container>
        </section>
      </main>
      {/* <SupportChat /> */}
    </>
  );
}
export default Faq1;
